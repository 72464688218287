import { AppNotificationTypeKeys } from 'store/appNotifications/types';
import { UiTypeKeys, ModalKey } from 'store/ui/types';
import { AuthTypeKeys, AuthLoadingKeys } from './types';

/**
 * A Blacklist of actions we do not want to dispatch a refresh action for. This
 * is used to attempt to set a flag that will mark our current user as "active",
 * which will in turn be used to update their session so that they aren't logged
 * out due to inactivity.
 *
 * Some of the actions (For instance, the one that comes back from the user _me)
 * call itself should definitely not trigger this, because otherwise an user
 * would never actually go inactive. Ergo, this blacklist.
 */
const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';
const blackListedPaths = [
  '/employee/signout',
  '/customer/signout',
  '/customer/signed-out',
  '/employee/signed-out',
];

const actionBlacklist = {
  [AppNotificationTypeKeys.SET_COUNTER]: true,
  [AuthTypeKeys.SET_USER_ACTIVE]: true,
  [AuthTypeKeys.UPDATE_SESSION]: true,
};

export const markUserAsActive = (store) => (next) => (action) => {
  const { type: actionType, loadingKey, payload } = action || {};
  const { SET_LOADING_STATE } = UiTypeKeys;
  const { authSessionTimeoutLoading } = AuthLoadingKeys;
  const sessionChanged =
    actionType === SET_LOADING_STATE &&
    loadingKey === authSessionTimeoutLoading;
  const routerPath = payload?.location?.pathname;
  const signedOut =
    actionType === LOCATION_CHANGE && blackListedPaths.includes(routerPath);
  const blackListed =
    actionBlacklist[actionType] ||
    (action.type === UiTypeKeys.SET_MODAL_STATE &&
      action.modalKey === ModalKey.SessionTimeoutModal);

  if (!blackListed && !sessionChanged && !signedOut) {
    // Blacklist certain paths so we don't set the user to active (e.g. logging out)
    store.dispatch({
      type: AuthTypeKeys.SET_USER_ACTIVE,
    });
  }

  return next(action);
};

/**
 * The action MARK_USER_AS_ACTIVE gets dispatched on many of our Redux actions.
 * We don't really want to spam the Redux log each time we get an action, so
 * what this middleware does is that it only allows the MARK_USER_AS_ACTIVE
 * action to be dispatched if the refresh state of our user session is false.
 * Otherwise it will just "eat" the action.
 */
export const markUserAsActiveLimiter = (store) => (next) => (action) => {
  if (
    action?.type !== AuthTypeKeys.SET_USER_ACTIVE ||
    !store.getState().auth.refresh
  )
    return next(action);
};
