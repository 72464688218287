import React, { CSSProperties } from 'react';
import ReactTooltip, { TooltipProps } from 'react-tooltip';
import styled from 'styled-components';
import { extremeBlack, shadow3 } from 'config/theme';

export interface IStyledReactTooltipProps extends TooltipProps {
  content?: string | React.ReactNode;
  /**
   * If given a value in milliseconds, The react tooltip won't hide until after
   * that time has passed
   */
  delayHide?: number;
  /**
   * If given a value in milliseconds, The react tooltip won't show until after
   * that time has passed
   */
  delayShow?: number;
  disable?: boolean;
  effect?: 'float' | 'solid';
  getContent?: () => React.ReactNode;
  id?: string;
  maxWidth?: string;
  multiline?: boolean;
  place?: 'top' | 'right' | 'bottom' | 'left';
  style?: React.CSSProperties;
  textAlign?: string;
  type?: 'dark' | 'light';
  thinner?: boolean;
  darkerShadow?: boolean;
  arrowColor?: string;
  fontSize?: string;
  lineHeight?: string;
  padding?: string;
  html?: boolean;
  className?: string;
}

export default ({
  content,
  delayHide,
  delayShow,
  disable,
  effect,
  getContent,
  id,
  maxWidth,
  multiline,
  place,
  style,
  textAlign,
  type,
  thinner,
  darkerShadow,
  arrowColor,
  fontSize,
  lineHeight,
  padding,
  html,
  overridePosition,
  className,
}: IStyledReactTooltipProps) => {
  let Wrapper = DarkWrapper;

  if (type === 'light') {
    Wrapper = LightWrapper;
  }
  return (
    <Tooltip
      delayHide={delayHide}
      delayShow={delayShow}
      disable={disable}
      effect={effect}
      getContent={getContent}
      id={id}
      maxWidth={maxWidth}
      multiline={multiline}
      place={place}
      textAlign={textAlign}
      type={type}
      thinner={thinner}
      darkerShadow={darkerShadow}
      fontSize={fontSize}
      lineHeight={lineHeight}
      padding={padding}
      html={html}
      arrowColor={arrowColor}
      overridePosition={overridePosition}
      className={className}
    >
      {!getContent && content && (
        <Wrapper maxWidth={maxWidth} style={style}>
          {content}
        </Wrapper>
      )}
    </Tooltip>
  );
};

interface ITooltipProps {
  maxWidth?: string;
  style?: CSSProperties;
  textAlign?: string;
  thinner?: boolean;
  type?: string;
  darkerShadow?: boolean;
  fontSize?: string;
  lineHeight?: string;
  padding?: string;
}

const Tooltip = styled(ReactTooltip)<ITooltipProps>`
  border: 0 !important;
  max-width: ${({ maxWidth }) => maxWidth || '400px'};
  text-align: ${({ textAlign }) => textAlign || 'left'};
  opacity: 1 !important;
  oveflow-wrap: break-word;
  white-space: normal;
  word-wrap: break-word;
  font-size: ${({ fontSize }) => fontSize || '12px'};
  line-height: ${({ lineHeight }) => lineHeight || '1.5'};
  ${({ padding }) => (padding ? `padding: ${padding};` : '')}
  font-weight: 400;
  letter-spacing: 0.004em;
  ${({ thinner }) => (thinner ? 'padding: 8px 10px;' : '')}
  ${({ darkerShadow }) =>
    darkerShadow &&
    `box-shadow: ${shadow3};`}
  &.place-bottom:after,
  &.place-left:after,
  &.place-right:after,
  &.place-top:after {
    border-left: 0 !important;
    border-right: 0 !important;
  }
`;
Tooltip.displayName = 'Tooltip';

const W = styled.div`
  box-shadow: ${shadow3};
  display: block !important;
  font-weight: 400;
  letter-spacing: 0.004em;
  line-height: 20px;
`;

const DarkWrapper = styled(W)<{ maxWidth?: string }>`
  border-radius: 3px;
  font-family: 'Circular';
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  margin: -8px -21px;
  padding: 12px;
`;
DarkWrapper.displayName = 'DarkWrapper';

const LightWrapper = styled(W)<{ maxWidth?: string }>`
  border-radius: 3px;
  color: ${extremeBlack};
  font-size: 14px;
  margin: -9px -21px;
  max-width: ${({ maxWidth }) => maxWidth || '300px'};
  padding: 25px;
  text-align: left;
  white-space: normal;
`;
LightWrapper.displayName = 'LightWrapper';
