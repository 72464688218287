import { IDocument } from 'store/orders/types';
import { IWireDocument } from 'store/universalWireAccounts/types';
import { BYTES_IN_ONE_KIBIBYTE } from './numbers';

export function pluralize(
  count: number,
  singular: string,
  irregularPlural?: string
) {
  if (count > 1 || count === 0) {
    return irregularPlural ? irregularPlural : `${singular}s`;
  }

  return singular;
}

export function truncate(text = '', length = 256, omission = '…') {
  if (!text) {
    return '';
  }

  return text.length > length
    ? `${text.substring(0, length).trim()}${omission}`
    : text;
}

export function lowerCase(text: string): string {
  if (!text || typeof text !== 'string') {
    return '';
  }

  return text.toLowerCase();
}

export function lowerCaseArray(arr: string[]): string[] {
  if (arr && arr.length < 1) {
    return [];
  }

  return arr.map((word) => lowerCase(word));
}

export function splitOnCaplitals(text: string): string[] {
  if (!text || typeof text !== 'string') {
    return [];
  }

  return text.split(/(?=[A-Z])/);
}

export function snakeCaseArray(arr: string[]): string {
  if (!arr || arr.length < 1) {
    return '';
  }

  return arr.join('_');
}

export function transformPascalCaseToSnakeCase(str: string): string {
  if (typeof str !== 'string' || str.length < 1) {
    return '';
  }

  return snakeCaseArray(lowerCaseArray(splitOnCaplitals(str)));
}

export function truncateFileExtension(
  text = '',
  length = 256,
  omission = '[…]'
): string {
  if (!text) {
    return '';
  }

  if (text.length <= length) {
    return text;
  }

  const ext = text
    .substring(text.lastIndexOf('.') + 1, text.length)
    .toLowerCase();
  const filename = text.slice(0).replace(`.${ext}`, '').substr(0, length);

  return `${filename}${text.length > length ? omission : ''}.${ext}`;
}

export const withoutFileExtension = (fileName: string = ''): string => {
  const parts = fileName.split('.');
  return (parts.length > 1 ? parts.slice(0, -1) : parts).join('.');
};

export const slugify = (text: string): string => {
  return text
    .toString()
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '') // remove non-word [a-z0-9_], non-whitespace, non-hyphen characters
    .replace(/[\s_-]+/g, '-') // swap any length of whitespace, underscore, hyphen characters with a single _
    .replace(/^-+|-+$/g, ''); // remove leading, trailing -
};

export const isEmpty = (str?: string | null): boolean => {
  return !str || !str.trim();
};

export const escapeRegExp = (str: string): string => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const removeAllExceptNumberAndConvertToNumber = (
  str: string
): number => {
  return +str.replace(/^0+/, '').replace(/[^0-9.]|\.(?=.*\.)/g, '');
};

export const removeAllExceptNumber = (str?: string) => {
  if (str) {
    return str.replace(/^0+/, '').replace(/[^0-9]|\.(?=.*\.)/g, '');
  } else {
    return '';
  }
};

export const getAsterisks = (
  value: string,
  retainValueLength: number = 4,
  minimumAsteriskLength: number = 11
) => {
  if (isEmpty(value)) {
    return '';
  }
  const charAsterisk = '*';
  let result = '';

  for (let i = 0; i < minimumAsteriskLength; i++) {
    result += charAsterisk;
  }
  result += value.substr(value.length - retainValueLength);

  const asteriskStringLength = minimumAsteriskLength + retainValueLength;
  if (result.length !== asteriskStringLength) {
    for (let i = 0; i <= asteriskStringLength - result.length; i++) {
      result = charAsterisk + result;
    }
  }
  return result;
};

export const formatBytes = (bytes: number) => {
  if (!bytes) {
    return '0 Bytes';
  }

  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const power = Math.floor(Math.log(bytes) / Math.log(BYTES_IN_ONE_KIBIBYTE));

  return `${parseFloat(
    (bytes / Math.pow(BYTES_IN_ONE_KIBIBYTE, power)).toFixed(1)
  )} ${sizes[power]}`;
};

export const sentenceToKebabCase = (str?: string | null): string => {
  if (str && typeof str === 'string') {
    return str.trim().replace(/\s+/g, '-').toLowerCase();
  } else {
    return '';
  }
};

export const isStringNumeric = (s: string) =>
  !isNaN((s as unknown) as number) && !isNaN(parseFloat(s));

export const hyphenize = (s: string) => s.replace(new RegExp(' ', 'g'), '-');

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

export const capitalizeSentence = (s: string) =>
  s
    .split(' ')
    .map((word) => capitalize(word))
    .join(' ');

export function camelize(input) {
  return input.replace(/^([A-Z])|[\s-_]+(\w)/g, function (_, f1, f2) {
    if (f2) return f2.toUpperCase();
    return f1.toLowerCase();
  });
}

export const getTotalFileSize = (files: File[]) => {
  if (!files || files.length < 1) {
    return 0;
  }
  let totalFileSize = 0;

  files?.forEach((file: File) => {
    totalFileSize += file.size;
  });

  return totalFileSize;
};

export const getTotalDocumentSize = (documents: IDocument[]) => {
  if (!documents || documents.length < 1) {
    return 0;
  }
  let totalFileSize = 0;

  documents.forEach((document: IDocument) => {
    totalFileSize += document.fileSize;
  });

  return totalFileSize;
};

export const getTotalExistingDocumentSize = (documents: IWireDocument[]) => {
  if (!documents || documents.length < 1) {
    return 0;
  }
  let totalFileSize = 0;

  documents.forEach((document: IWireDocument) => {
    totalFileSize += document.externalDocumentSize;
  });

  return totalFileSize;
};

export function commaSeparatedListify(strings: string[], lastJoin?: string) {
  if (strings.length === 0) return '';
  if (strings.length === 1) return strings[0];
  if (strings.length === 2)
    return `${strings[0]} ${lastJoin || 'and'} ${strings[1]}`;
  return strings
    .slice(0, strings.length - 1)
    .concat(`${lastJoin || 'and'} ${strings[strings.length - 1]}`)
    .join(', ');
}

export function appendNumericSuffix(i: number): string {
  const j = i % 10;
  const k = i % 100;

  if (j === 1 && k !== 11) {
    return `${i}st`;
  } else if (j === 2 && k !== 12) {
    return `${i}nd`;
  } else if (j === 3 && k !== 13) {
    return `${i}rd`;
  }

  return `${i}th`;
}

const EM_DASH: string = '—';
export const getValueOrDefault = (
  value: string | null | undefined,
  defaultValue: string = EM_DASH
) => {
  if (!value || value === 'undefined') {
    return defaultValue;
  }
  return value;
};

const documentPattern = {
  tin: {
    regex: /^(\d{2})-?(\d{7})$/,
    template: '$1-$2',
  },
  ssn: {
    regex: /^(\d{3})-?(\d{2})-?(\d{4})$/,
    template: '$1-$2-$3',
  },
};

export const formatUserDocument = (document: string, documentType: string) => {
  const formattedDocument = getValueOrDefault(document);

  if (!documentPattern[documentType]) return formattedDocument;

  return formattedDocument.replace(
    documentPattern[documentType].regex,
    documentPattern[documentType].template
  );
};

export const ordinalize = (num: number) => {
  const ordinals = {
    1: 'st',
    2: 'nd',
    3: 'rd',
  };

  return `${num}${ordinals[num] || 'th'}`;
};

export const concatenateStrings = (
  input: string[],
  separator: string = ' '
) => {
  if (!input.length) return '';
  return input.filter(Boolean).join(separator);
};

export function formatSentencePeriod(text?: string) {
  if (!text) return;
  return text.endsWith('.') ? text : `${text}.`;
}

export function replaceText(text?: string, replaceFor = '-', replaceWith = '') {
  if (!text) return;
  return text.replaceAll(replaceFor, replaceWith);
}

export function getAlphabet(number: number) {
  if (number < 1) return '';
  if (number > 26) return '';
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  return alphabet[number - 1];
}

export function replaceNewLineCharacters(
  text,
  replaceWith = ' ',
  replaceConsecutiveNewlines = false
) {
  const regex = replaceConsecutiveNewlines
    ? /(\r\n|\n|\r|\u2028|\u2029)+/gm
    : /(\r\n|\n|\r|\u2028|\u2029)/gm;
  if (!text) return;
  return text.replace(regex, replaceWith);
}
