import { IDisbursement } from './../../components/WireInstructions/WireInstructionDisbursements/DisbursementsSelector/types';
import { IToasterProps } from 'components/Toaster/types';
import { AxiosError } from 'axios';
import { IDocument } from 'store/orders/types';
import { IWireDocument } from 'store/universalWireAccounts/types';

export enum WireInstructionsTypeKeys {
  ENTITY = '[wireInstructions]',

  // Generic actions used to update current request state
  REQUEST = 'wireInstructions/REQUEST',
  SUCCESS = 'wireInstructions/SUCCESS',
  ERROR = 'wireInstructions/ERROR',
  CLEAR_ERROR = 'wireInstructions/CLEAR_ERROR',
  CLEARTOASTER = 'wireInstructions/CLEARTOASTER',

  // Set file currently being worked on and clear file parties/contacts
  SET_FILE = 'wireInstructions/SET_FILE',

  // Store different types of data received via XHR
  SET_REASON_UPDATE = 'wireInstructions/SET_REASON_UPDATE',
  RECEIVE_FILE_PARTIES = 'wireInstructions/RECEIVE_FILE_PARTIES',
  RECEIVE_FILE_CONTACTS = 'wireInstructions/RECEIVE_FILE_CONTACTS',
  RECEIVE_WIRE_INSTRUCTIONS_REQUEST = 'wireInstructions/RECEIVE_WIRE_INSTRUCTIONS_REQUEST',
  RECEIVE_WIRE_INSTRUCTION_DETAILS = 'wireInstructions/RECEIVE_WIRE_INSTRUCTION_DETAIL',
  RECEIVE_WIRE_INSTRUCTION_RESULT = 'wireInstruction/RECEIVE_WIRE_INSTRUCTION_RESULT',
  RECEIVE_WIRE_INSTRUCTION_VERIFICATION = 'wireInstructions/RECEIVE_WIRE_INSTRUCTION_VERIFICATION',
  VERIFY_WIRE_INSTRUCTION = 'wireInstructions/VERIFY_WIRE_INSTRUCTION',
  RESET_VERIFY_WIRE_INSTRUCTION = 'wireInstructions/RESET_VERIFY_WIRE_INSTRUCTION',
  SELECTED_INACTIVE_MATCH = 'wireInstructions/SELECTED_INACTIVE_MATCH',
  DELETE_INACTIVE_MATCH = 'wireInstructions/DELETE_INACTIVE_MATCH',
  GET_WIRE_INSTRUCTIONS = 'GET_WIRE_INSTRUCTIONS',
  SET_WIRE_INSTRUCTIONS = 'SET_WIRE_INSTRUCTIONS',

  GET_DISBURSEMENTS = 'wireInstructions/GET_DISBURSEMENTS',
  GET_DISBURSEMENTS_ERROR = 'wireInstructions/GET_DISBURSEMENTS_ERROR',
  GET_DISBURSEMENTS_SUCCESS = 'wireInstructions/GET_DISBURSEMENTS_SUCCESS',
  RESET_DISBURSEMENTS = 'wireInstructions/RESET_DISBURSEMENTS',
  SEND_DISBURSEMENT_TO_FAST = 'wireInstructions/SEND_DISBURSEMENT_TO_FAST',
  SEND_DISBURSEMENT_TO_FAST_SUCCESS = 'wireInstructions/SEND_DISBURSEMENT_TO_FAST_SUCCESS',
  SEND_DISBURSEMENT_TO_FAST_ERROR = 'wireInstructions/SEND_DISBURSEMENT_TO_FAST_ERROR',

  UPDATE_WIRE_INSTRUCTIONS_REQUEST = 'wireInstructions/UPDATE_WIRE_INSTRUCTIONS_REQUEST',
  UPDATE_WIRE_INSTRUCTIONS_SUCCESS = 'wireInstructions/UPDATE_WIRE_INSTRUCTIONS_SUCCESS',
  UPDATE_WIRE_INSTRUCTIONS_RESET = 'wireInstructions/UPDATE_WIRE_INSTRUCTIONS_RESET',

  SET_WIRE_ACCOUNT_ACTIVITY = 'wireInstructions/SET_WIRE_ACCOUNT_ACTIVITY',

  UPLOAD_DOCUMENT_REQUEST = 'wireInstructions/UPLOAD_DOCUMENT_REQUEST',
  UPLOAD_DOCUMENT_SUCCESS = 'wireInstructions/UPLOAD_DOCUMENT_SUCCESS',
  UPLOAD_DOCUMENT_ERROR = 'wireInstructions/UPLOAD_DOCUMENT_ERROR',
  CLEAR_UPLOAD_DOCUMENT_ERROR = 'wireInstructions/CLEAR_UPLOAD_DOCUMENT_ERROR',
  UPDATE_WIREINSTRUCTION_DOCUMENTS_RESET = 'wireInstructions/UPDATE_WIREINSTRUCTION_DOCUMENTS_RESET',

  GET_ABA_DETAILS = 'wireInstructions/GET_ABA_DETAILS',
  RESET_ABA_DETAILS = 'wireInstructions/RESET_ABA_DETAILS',
  GET_SWIFT_DETAILS = 'wireInstructions/GET_SWIFT_DETAILS',
  RESET_SWIFT_DETAILS = 'wireInstructions/RESET_SWIFT_DETAILS',
  RESET_ADDTIONAL_WIRE_INSTRUCTION_RESULT = 'wireInstructions/RESET_ADDTIONAL_WIRE_INSTRUCTION_RESULT',
  RECEIVE_ADDTIONAL_WIRE_INSTRUCTION_RESULT = 'wireInstruction/RECEIVE_ADDTIONAL_WIRE_INSTRUCTION_RESULT',
  RECEIVE_LATEST_ACCOUNT_PAYEE_NAMES = 'wireInstruction/RECEIVE_LATEST_ACCOUNT_PAYEE_NAMES',

  // Multiple Document Uploads
  STAGE_DOCUMENT_UPLOADS = 'wireInstructions/STAGE_DOCUMENT_UPLOADS',
  INCREMENT_DOCUMENT_INDEX = 'wireInstructions/INCREMENT_DOCUMENT_INDEX',
  UPDATE_DOC_UPLOAD_PROGRESS = 'wireInstructions/UPDATE_DOC_UPLOAD_PROGRESS',
  UPDATE_DOC_UPLOAD_COMPLETE = 'wireInstructions/UPDATE_DOC_UPLOAD_COMPLETE',
  RESET_DOCUMENT_INDEX = 'wireInstructions/RESET_DOCUMENT_INDEX',
  RESET_UPLOAD_COMPLETE = 'wireInstructions/RESET_UPLOAD_COMPLETE',
  RESET_STAGE_DOCUMENT_UPLOADS = 'wireInstructions/RESET_STAGE_DOCUMENT_UPLOADS',

  STAGE_EXISTING_DOCUMENT_UPLOADS = 'wireInstructions/STAGE_EXISTING_DOCUMENT_UPLOADS',
  STAGE_EXTERNAL_DOCUMENT_UPLOADS = 'wireInstructions/STAGE_EXTERNAL_DOCUMENT_UPLOADS',

  DELETE_STAGE_DOCUMENT_UPLOADED = 'wireInstructions/DELETE_STAGE_DOCUMENT_UPLOADED',
  REMOVED_DOCUMENTS = 'wireInstructions/REMOVED_DOCUMENTS',
  DELETE_STAGE_EXTERNAL_DOCUMENT_UPLOADED = 'DELETE_STAGE_EXTERNAL_DOCUMENT_UPLOADED',
}

export interface IWireInstructionsState {
  loading: boolean;
  selectedInactiveMatch: boolean;
  error: string | null | AxiosError;
  toaster: IToasterProps | null;
  file: string | null;
  reasonForUpdateWireInstruction: string | null;
  fileParties: IBusinessParty[];
  fileContacts: IBusinessOrganizationContacts;
  wireInstructionsRequest: IWireInstructionsRequest | null;
  wireInstructionsResults: IWireInstructions[];
  wireInstructionDetails: IWireInstructionDetails | null;
  wireInstructionVerification: IWireInstructionVerification | null;
  wireInstructionVerificationCompleted: boolean;
  wireDisbursements: IWireDisbursementsState;
  wireInstructionUpdateId: string | number | null;
  wireAccountActivity: IWireAccountActivity | null;
  bankABADetails: IBankABADetails | null;
  swiftCodeDetails: ISwiftBankDetails | null;
  uploadDocument: IUploadDocument;
  additionalWireInstructionsResults: IWireInstructions[];
  accountPayeeNames: string[];
  wireInstructionDocumentsMultiUpload: {
    documentIndex: number;
    stagedDocuments: File[];
    stagedExistingDocuments: IDocument[];
    stagedExternalDocuments: IWireDocument[];
    inProgressUploads: IInProgressWireInstructionDocumentUpload[];
    removedDocuments?: number[];
  };
}

export enum WireInstructionLoadingKeys {
  wireInstructionLoading = 'wireInstructionLoading',
  addWireInstructionLoading = 'addWireInstructionLoading',
  updateWireInstructionLoading = 'updateWireInstructionLoading',
  byPassVerificationLoading = 'byPassVerificationLoading',
  byPassBankVerificationLoading = 'byPassBankVerificationLoading',
  byPassUserVerificationLoading = 'byPassUserVerificationLoading',
  byPassNewWILoading = 'byPassNewWILoading',
  revokeAuthorizationWireInstructionLoading = 'revokeAuthorizationWireInstructionLoading',
  additionalWireInstructionsResultsLoading = 'additionalWireInstructionsResultsLoading',
  latesAccountPayeeNamesLoading = 'latesAccountPayeeNamesLoading',
}

// InProgress Document
export interface IInProgressWireInstructionDocumentUpload {
  documentIndex: number;
  progress: number; // 0 - 100
  externalDocumentId: number;
  file: File;
}

export interface IBankABADetails {
  name: string;
}
export interface ISwiftBankDetails {
  countryName: string;
}

export interface IUploadDocumentMessages {
  error?: string;
  success?: string;
}

export interface IUploadDocument {
  loading: boolean;
  id?: string;
  error: AxiosError | string | null;
  success?: string | null;
}

export interface IWireInstructions {
  id: number;
  wireInstructionFileId: number;
  dealPartyOrganizationId: string;
  dealPartyOrganizationName: string;
  accountHolderName: string;
  lastUpdated: string;
  lastUpdatedForDefaultSort: string;
  lastStatusChangeDate: string;
  wireInstructionStatus: WireInstructionStatus;
  fileAssociationStatus: FileAssociationStatus;
  status: string;
  fileId: string;
  fileNumber: string;
  dealPartyName: string;
  dealPartyRole: string;
  dealPartyEmail: string;
  isLocked: boolean;
  externalDocumentId: number;
  attachedDisbursementsCount?: number;
  accountNumber: string;
  bankName: string;
  routingNumber: string;
  originalFileId: string;
  originalFileNumber: string;
  originalLogDescription1: string;
  originalLogEventDate1: string;
  originalLogDescription2: string;
  originalLogEventDate2: string;
  verificationExpirationDate?: Date;
  updatedReason: string;
  beneficiarySwiftCode?: string;
  beneficiaryBankName?: string;
  isWireVerified?: boolean;
  isInternationalWire?: boolean;
  isActiveWireTabSelect?: boolean;
  safeValidationCode?: string;
  safeValidationMessage?: string;
  safeValidationFriendlyMessage?: string;
  isSelectedFromExistingWire?: boolean;
  isPayoffAccount?: boolean | null;
  zip?: number;
}

interface IWireInstructionDetails {
  id: string;
  accountHolderName: string;
  accountNumber: string;
  bankName: string;
  note1: string;
  note2: string;
  note3: string;
  note4: string;
  documentUrl: string;
  bankRoutingNumber: string;
  dealPartyOrganizationId: string;
  externalDocumentId?: string;
  beneficiarySwiftCode?: string;
  beneficiaryBankName?: string;
  isPayoffAccount?: boolean | null;
  zip?: number;
}

export interface IWireInstructionsRequest {
  wireInstructionId: number;
  wireInstructionFileId: number;
  dealPartyRole: string;
  dealPartyName: string;
  dealPartyEmail: string;
  dealPartyOrganizationId: string;
  dealPartyOrganizationName: string;
  dealPartyId: number;
  fileNumber: string;
  fileId: string;
  updatedOn: string;
}

export interface ISearchWireInstructionRequest {
  routingNumber: string;
  accountNumber: string;
  dealPartyOrganizationName: string;
  beneficiarySwiftCode?: string;
}

export interface IWireInstructionForm {
  wireInstructionId?: number;
  fileId: string;
  fileNumber: string;
  dealPartyRole?: string;
  dealPartyOrganizationId: string;
  dealPartyName: string;
  dealPartyEmail: string;
  dealPartyId?: number;
  dealPartyOrganizationType: string;
  dealPartyOrganizationName: string;
  accountHolderName: string;
  accountNumber: string;
  bankName: string;
  beneficiarySwiftCode?: string;
  beneficiaryBankName?: string;
  note1: string;
  note2: string;
  note3: string;
  note4: string;
  wireType: string;
  payeeTypeValue?: number | null;
  documentUrl?: string;
  bankRoutingNumber: string;
  redirectUrl?: string;
  successMessage?: string;
  externalDocument?: File[];
  externalDocumentId: number[];
  verificationExpirationDate?: Date;
  isWireVerified?: boolean;
  isInternational: boolean;
  accountHolderNameDifferenceReason?: string;
  fromEmailAddress?: string;
  fromEmailName?: string;
  isEmailSendOnBehalfChecked?: boolean;
  inProgressUploads: IInProgressWireInstructionDocumentUpload[];
  sendNotification?: boolean;
  finalizeWire?: boolean;
  isPayoffAccount?: boolean | null;
  uniqueTransactionId?: string;
  zip?: number;
  mfaPhoneNumber?: string;
  mfaPhoneCountryCode?: string;
}
interface IInternationalWireQuestionnaires {
  questionText: string;
  answerText: string;
}
export interface IUpdateWireInstructionForm {
  fileId: string;
  wireInstructionId: number;
  wireInstructionFileId: number;
  accountHolderName: string;
  accountNumber: string;
  bankName: string;
  note1: string;
  note2: string;
  note3: string;
  note4: string;
  documentUrl?: string;
  bankRoutingNumber: string;
  externalDocument?: File[];
  externalDocumentId?: number[];
  redirectUrl?: string;
  removedDocumentId?: string;
  updatedOn?: string;
  verificationExpirationDate?: Date | null;
  beneficiarySwiftCode?: string;
  beneficiaryBankName?: string;
  internationalWireQuestionnaires: IInternationalWireQuestionnaires[];
  isIntWireQuestionsUpdated: boolean;
  isWireVerified: boolean;
  safeValidationCode?: string;
  safeValidationMessage?: string;
  safeValidationFriendlyMessage?: string;
  isPayoffAccount: boolean | null;
  payeeTypeValue?: number | null;
  payeeTypeDescription?: string | null;
  zip?: number;
  loanPayoffResponse?: IValidateLoanPayoffAccount;
}

export interface IUpdateVerifiedWireInstructionForm {
  externalDocumentIds: number[];
  note1: string;
  note2: string;
  note3: string;
  note4: string;
  reasonForUpdate: string;
}

export interface IWireInstructionVerification {
  code: string;
  wireInstructionId: number;
  fileId: string;
  fileNumber: string;
  dealPartyName: string;
  dealPartyRole: string;
  dealPartyOrganizationId: string;
  dealPartyOrganizationName: string;
  dealPartyEmail: string;
  accountHolderName: string;
  accountNumber: string;
  bankName: string;
  beneficiaryBankName: string;
  beneficiarySwiftCode: string;
  note1: string;
  note2: string;
  note3: string;
  note4: string;
  documentUrl: string;
  bankRoutingNumber: string;
  hasValidCodeVerification: boolean;
  updatedOn: string;
}

export interface ICreateWireInstructionRequestParams {
  dealPartyName: string;
  dealPartyOrganizationId: string;
  dealPartyOrganizationName: string;
  dealPartyEmail: string;
  dealPartyOrganizationType: string;
  fileNumber: string;
  fileId: string;
  fromEmailAddress: string;
  fromEmailName: string;
  isEmailSendOnBehalfChecked: boolean;
  isPayoffAccount: boolean;
  zip?: number;
  mFAPhoneNumber?: string;
}

export interface IUpdateWireInstructionRequestParams {
  dealPartyName: string;
  dealPartyEmail: string;
  dealPartyOrganizationName: string;
  fileId: string;
}

export interface IVerifyWireInstructionRequest {
  code: string;
  updatedOn: string;
  verificationExpirationDate?: Date | null;
  isWireVerified?: boolean;
}

export interface IBusinessParty {
  id: number;
  name: string;
  roleId: number;
  roleName: string;
  roleDescription: string;
  organization: IBusinessOrganization;
}

interface IBusinessOrganization {
  id: number;
  contact: IBusinessContact | null;
  contactInfo: IBusinessContactInfo | null;
  address: IBusinessContactAddress | null;
}

interface IBusinessOrganizationContacts {
  [organizationId: string]: IBusinessContact[];
}

interface IBusinessContact {
  name: string;
  contactInfo: IBusinessContactInfo;
}

interface IBusinessContactInfo {
  email: string | null;
  phone: string | null;
  cellular: string | null;
  homePhone: string | null;
  homeFax: string | null;
  businessPhone: string | null;
  businessFax: string | null;
}

interface IBusinessContactAddress {
  street: string | null;
  city: string | null;
  county: string | null;
  state: string | null;
  zip: string | null;
  apns: string[] | null;
}

interface IInternationalWireQuestionnaires {
  questionText: string;
  answerText: string;
}

export interface IBypassBankVerificationActionProps {
  wireInstructionFileId?: number;
  fileId?: string | number;
  redirect?: string;
  reasonForUpdate?: string;
  successMessage?: any;
  sendNotification?: boolean;
  additionalWireInstructionsId?: number;
  documentDetails?: IDocumentDetails[];
  userType?: string;
  submittedBy?: string;
  employeeWhoVerified?: string;
  dateVerified?: string;
  payeeContactName?: string;
  payeeContactPhoneNumber?: string;
  verificationType?: string;
  internationalWireQuestionnaires?: IInternationalWireQuestionnaires[];
  email?: string;
  code?: string;
  firstName?: string;
  lastName?: string;
  fromEmailName?: string;
  fromEmailAddress?: string;
  isEmailSendOnBehalfChecked?: boolean;
  finalizeWire?: boolean;
  isWireVerified?: boolean;
}

export interface IDocumentDetails {
  documentId: number;
  documentName: string;
}

export interface IRevokeAuthorizationActionProps {
  id: string;
  fileId: string;
  reasonForUpdate?: string;
  dealPartyOrganizationName: string;
  isEmailSendOnBehalfChecked?: boolean;
  fromEmailAddress?: string;
  fromEmailName?: string;
  sendNotification?: boolean;
}

interface IWireDisbursementsState {
  fetching: boolean;
  submitting: boolean;
  data: IDisbursement[] | null;
}

export interface IWireAccountActivity {
  wireInstructionFileId: number;
  activities: IActivity[];
}

interface IActivity {
  description: string;
  eventDate: string;
}

export enum editableWireStatus {
  AwaitingBankVerification = 'AwaitingBankVerification',
  BankVerificationFailed = 'BankVerificationFailed',
  BankVerified = 'BankVerified',
  Requested = 'Requested',
  Invalidated = 'Invalidated',
}

export enum FileAssociationStatus {
  Created = 'Created',
  Requested = 'Requested',
  Updated = 'Updated',
  NotVerified = 'NotVerified',
  AwaitingUserVerification = 'AwaitingUserVerification',
  UserVerified = 'UserVerified',
  UserVerificationFailed = 'UserVerificationFailed',
  DisbursementMatched = 'DisbursementMatched',
  InternallyVerified = 'InternallyVerified',
  Unlinked = 'Unlinked',
}

export enum WireInstructionStatus {
  Created = 'Created',
  Requested = 'Requested',
  Updated = 'Updated',
  AwaitingBankVerification = 'AwaitingBankVerification',
  BankVerified = 'BankVerified',
  BankVerificationFailed = 'BankVerificationFailed',
  Invalidated = 'Invalidated',
}

export enum removedWireStatus {
  Unlinked = 'Unlinked',
}
export interface IDeleteWireInstruction {
  wireInstructionFileId: number;
  fileId: string;
  reasonForUpdate?: string;
  successMessage?: string;
  redirectUrl?: string;
  cb?: () => void;
  sendNotification: boolean;
  fromEmailName: string;
  fromEmailAddress: string;
  isEmailSendOnBehalfChecked: boolean;
}

export interface IWireInvitation {
  email?: string;
  code?: string;
  firstName?: string;
  lastName?: string;
  fileId?: number;
  fromEmailAddress?: string;
  fromEmailName?: string;
  isEmailSendOnBehalfChecked?: boolean;
}

export interface IRemoveWireInstructionDocumentParams {
  wireInstructionFileId: number;
  fileId: number;
  documentId: number;
  removeDocumentFromOrder: boolean;
}

export interface IRequireReason {
  reasonForUpdate?: string;
  fromEmailName?: string;
  fromEmailAddress?: string;
  isEmailSendOnBehalfChecked?: boolean;
}

export enum disbursableWires {
  UserVerified = 'UserVerified',
  InternallyVerified = 'InternallyVerified',
}

export enum AddNewWireInstructionsButtons {
  saveForReview = 'Save For Review',
  bypassVerification = 'Bypass Verification',
  requestVerification = 'Request Verification',
  alternateVerification = 'Alternate Verification',
}

export enum VerificationTypes {
  alternateVerification = 'Alternate Verification',
  bypassVerification = 'Bypass Verification',
  verifyWireInstructions = 'Verify Wire Instructions',
}

export enum SafeValidationResult {
  Info = 'info',
  Success = 'success',
  AltInfo = 'altInfo',
  ErrorCircle = 'errorCircle',
  SuccessShield = 'successShield',
  Caution = 'caution',
  Warning = 'warning',
  Stop = 'stop',
  Error = 'error',
}

export const SV_DOWN = 'SVDown';
const SAFE_VALIDATION_RESULT_CODE = [
  {
    code: [
      'PF07',
      'AF03',
      'VF07',
      'VF09',
      'AF10',
      'VF06',
      'AF09',
      'AF12',
      'AF02',
      SV_DOWN,
      ['default'],
    ],
    type: SafeValidationResult.Warning,
    severity: 'Warning',
  },
];

export const filteredSafeValidationResult = (
  code?: string,
  severity?: string | null
) =>
  SAFE_VALIDATION_RESULT_CODE.find((item) => {
    if (code) {
      const isCodeExists = item.code.includes(code);
      return isCodeExists ? isCodeExists : item.code.includes('default');
    } else {
      return (
        severity &&
        item.severity.trim().toUpperCase() === severity?.trim().toUpperCase()
      );
    }
  });

export interface IValidateLoanPayoffAccount {
  bankName: string;
  routingNumber: number;
  accountNumber: string;
  uniqueTransactionId: string;
  isPayoffAccount: boolean | null;
}

export interface IValidateBankLoanPayoffAccount
  extends IValidateLoanPayoffAccount {
  payee: string;
  payeeType: number;
  routingNumber: number;
  zip: string;
}
export interface ILoanPayoffResponse {
  severity: string;
  validationCode: string;
  validationFriendlyMessage: string;
  validationMessage: string;
  uniqueTransactionId: string;
}

export interface IIndefiniteAuth {
  beneficiarySwiftCode: string;
  accountNumber: string;
  bankRoutingNumber: string;
}
