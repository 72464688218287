import React from 'react';
import uuidv4 from 'uuid/v4';
import { AxiosError, AxiosInstance } from 'axios';
import workspaceAPI from 'services/workspaceAPI';
import {
  OrderTypeKeys,
  OrderRequestTypes,
  OrderPartyGroup,
  IOrderRequestAction,
  IOrderSuccessAction,
  IOrderErrorAction,
  IOrderReceiveResultsAction,
  IOrderProductsReceiveResultsAction,
  IOrderBusinessPartiesReceiveResultsAction,
  IOrderProduct,
  ProductionOfficeType,
  IOrderTitleProductionOfficesReceiveResultsAction,
  IProductionOffice,
  IOrderPartiesReceiveResultsAction,
  IOrderEscrowProductionOfficesReceiveResultsAction,
  IOrderBusinessParty,
  IOrderClearAction,
  IOrderOtherPartiesReceiveResultsAction,
  IOrderSetFilterCardStatus,
  IOrdersetOfficeViewSearchValue,

  // @TODO to be moved to documents/types
  IDocument,
  IEagleProPackage,
  IOrderDocumentsAction,
  IOrderGetLatestEpPackageSetLatestAction,
  IOrderSetLatestEpPackageErrorAction,
  IOrderSetMultiDocsOrderIdAction,
  OrderLoadingKeys,
  IOrderClearErrorAction,
  IOrderSetMultiDocsDocumentIdsAction,
  IOrderSetMultiDocsDownloadPromptVisibilityAction,
  OrderBusinessPartySourceType,
  IOrderSetDocPermissionsContactInputIdsAction,
  IOrderSetSingleDocDownloadDocumentIdAction,
  IOrderClearSingleDocDownloadDocumentIdAction,
  IOrderDeposit,
  IOrderDocumentContentAction,
  IOrder,
  IOrderSetMultisiteSelectionAction,
  IDepositReceiptProgressMetaData,
  IOrderReceiptProgress,
  IOrderClearReceiptProgress,
  IIssueCheckDocumentProgressMetaData,
  IIssueCheckProgress,
  IClearIssueCheckProgress,
  ISettlementDocumentItemProgress,
  ISettlementDocumentProgress,
  IClearSettlementDocumentProgress,
  ISetBulkUpdatesStatusProgress,
  IMultisiteSettlementDocumentProgress,
  IClearMultisiteSettlementDocumentProgress,
  IMultisiteDocumentItem,
  DocumentId,
  IMilestoneDeposit,
  IOwningOffice,
  IOrderOwningOfficesReceiveResultAction,
} from '../types';
import { setLoadingStateAction, addToast } from 'store/ui/actions';
import { slugify } from 'utils/text';
import { AsyncAction, IApplicationState, Dispatch } from 'store';
import { trackExceptions } from 'utils/appInsights';
import { showNotification } from 'store/notifications/actions';
import notificationTheme from 'components/Tile/themes';
import { FILE_NOT_FOUND } from 'utils/constants';
import {
  DocumentExtensions,
  getDocumentContentType,
} from 'utils/documentExtensions';
import { downloadBlob } from 'utils/browser';
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { IBulkUpdateStatus } from 'components/MultiSiteBulkUpdates/Shared';
import { toast } from 'components/Snackbar';
import client from 'services/workspaceAPI/client';
import {
  setSingleDownloadErrorAction,
  setSingleDownloadStartAction,
  setSingleDownloadSuccessAction,
} from 'store/documents/actions';
import DownloadSingleDocumentToast from 'components/OrderDocuments/DownloadSingleDocumentToast';

export * from './editOrder';
export * from './orderContacts';
export * from './orderDisbursements';
export * from './orderDocuments';
export * from './orderOverdrafts';
export * from './orderSites';

const getDocumentIdsURL = (documentIDs: number[]) => {
  return documentIDs.map((id: number) => `documentIds=${id}`).join('&');
};

export const orderRequestAction = (
  requestType: OrderRequestTypes
): IOrderRequestAction => {
  return {
    type: OrderTypeKeys.REQUEST,
    requestType,
  };
};

export const orderSuccessAction = (): IOrderSuccessAction => {
  return {
    type: OrderTypeKeys.SUCCESS,
  };
};

export const orderErrorAction = (
  error: AxiosError | null
): IOrderErrorAction => {
  return {
    type: OrderTypeKeys.ERROR,
    error,
  };
};

export const orderReceiveAction = (
  order,
  updateOrderDataOnly?: boolean
): IOrderReceiveResultsAction => {
  return {
    type: OrderTypeKeys.RECEIVE_ORDER_RESULTS,
    order,
    updateOrderDataOnly,
  };
};

export const orderClearAction = (): IOrderClearAction => {
  return {
    type: OrderTypeKeys.CLEAR,
  };
};

export const setFilterCardStatus = (status): IOrderSetFilterCardStatus => {
  return {
    type: OrderTypeKeys.SET_FILTER_CARD_STATUS,
    status,
  };
};

export const setOfficeViewSearchValue = (
  officeViewSearchValue
): IOrdersetOfficeViewSearchValue => {
  return {
    type: OrderTypeKeys.SET_OFFICE_VIEW_SEARCH_VALUE,
    officeViewSearchValue,
  };
};

export const orderClearErrorAction = (): IOrderClearErrorAction => {
  return {
    type: OrderTypeKeys.CLEAR_ERROR,
  };
};

export async function fetchOrder(fileId: string) {
  return workspaceAPI.get<IOrder>(`/orders/${fileId}`);
}

export const orderOwningOfficesAction = (
  owningOffices: Array<IOwningOffice | IProductionOffice>
): IOrderOwningOfficesReceiveResultAction => {
  return {
    type: OrderTypeKeys.RECEIVE_ORDER_OWNING_OFFICES_RESULTS,
    owningOffices,
  };
};

export function getOwningOfficesDetails(fileId: string): AsyncAction<void> {
  return (dispatch) => {
    dispatch(
      setLoadingStateAction(OrderLoadingKeys.orderFileDetailsLoading, true)
    );
    dispatch(
      setLoadingStateAction(
        OrderLoadingKeys.orderTeamPanelBusinessPartiesLoading,
        true
      )
    );

    return fetchOrder(fileId)
      .then(({ data }) => {
        dispatch(orderOwningOfficesAction(data.offices));
        return workspaceAPI.get(`/files/${fileId}/business-party-employees`);
      })
      .then(({ data }) => {
        dispatch(orderBusinesPartiesAction(data));
      })
      .catch((error) => {
        dispatch(orderErrorAction(error));
      })
      .finally(() => {
        dispatch(
          setLoadingStateAction(OrderLoadingKeys.orderFileDetailsLoading, false)
        );
        dispatch(
          setLoadingStateAction(
            OrderLoadingKeys.orderTeamPanelBusinessPartiesLoading,
            false
          )
        );
      });
  };
}

export function getOrderDetails(
  fileId: string,
  updateOrderDataOnly?: boolean
): AsyncAction<void> {
  return (dispatch) => {
    // @TODO: Remove the following line once all LiveDeals views are updated to lazy loaders
    dispatch(orderRequestAction(OrderRequestTypes.ORDER));
    dispatch(
      setLoadingStateAction(OrderLoadingKeys.orderFileDetailsLoading, true)
    );

    return fetchOrder(fileId)
      .then(({ data }) => {
        dispatch(orderReceiveAction(data, updateOrderDataOnly));
        dispatch(orderSuccessAction());
      })
      .catch((error) => {
        dispatch(orderErrorAction(error));
      })
      .finally(() => {
        dispatch(
          setLoadingStateAction(OrderLoadingKeys.orderFileDetailsLoading, false)
        );
      });
  };
}

export const getOrderDeposits = (
  orderId: string,
  includedStatuses?: string[],
  showToast = true
): AsyncAction<void> => {
  return (dispatch) => {
    dispatch(
      setLoadingStateAction(OrderLoadingKeys.orderDepositsLoading, true)
    );

    return workspaceAPI
      .get<{ deposits: IOrderDeposit[] }>(`/orders/${orderId}/deposits`)
      .then(({ data }) => {
        const deposits = data.deposits.map(({ issueDate, ...deposit }) => ({
          ...deposit,
          // issueDate comes over the wire as a string. We need to hydrate
          // this into a JS Date object
          issueDate: issueDate ? new Date(issueDate) : null,
        }));

        dispatch({
          type: OrderTypeKeys.REQUEST_ORDER_DEPOSITS_SUCCESS,
          deposits: includedStatuses?.length
            ? deposits.filter((d) => includedStatuses.includes(d.status))
            : deposits,
        });
      })
      .catch((error) => {
        dispatch({
          type: OrderTypeKeys.REQUEST_ORDER_DEPOSITS_ERROR,
          error,
        });
        if (showToast)
          addToast(
            'There was an error loading earnest money deposits.',
            'error'
          )(dispatch);
      })
      .finally(() => {
        dispatch(
          setLoadingStateAction(OrderLoadingKeys.orderDepositsLoading, false)
        );
      });
  };
};

export const trackGenerateReceiptProgress = (
  receiptsMetaData: IDepositReceiptProgressMetaData[]
): IOrderReceiptProgress => {
  return {
    type: OrderTypeKeys.SET_ORDER_DEPOSIT_RECEIPT_PROGRESS,
    receiptsMetaData,
  };
};

export const trackIssueCheckProgress = (
  items: IIssueCheckDocumentProgressMetaData[]
): IIssueCheckProgress => {
  return {
    type: OrderTypeKeys.SET_ISSUE_CHECK_DOCUMENT_PROGRESS,
    issueCheckMetaData: items,
  };
};

export const clearIssueCheckProgress = (
  item: IIssueCheckDocumentProgressMetaData | null
): IClearIssueCheckProgress => {
  return {
    type: OrderTypeKeys.CLEAR_ISSUE_CHECK_DOCUMENT_PROGRESS,
    issueCheckMetaData: item,
  };
};

export const trackSettlementDocumentProgress = (
  items: ISettlementDocumentItemProgress[]
): ISettlementDocumentProgress => {
  return {
    type: OrderTypeKeys.SET_SETTLEMENT_DOCUMENT_PROGRESS,
    items,
  };
};

export const trackMultisiteSettlementDocumentProgress = (
  items: IMultisiteDocumentItem[]
): IMultisiteSettlementDocumentProgress => {
  return {
    type: OrderTypeKeys.MULTISITE_ORDER_SET_SETTLEMENT_DOCUMENT_PROGRESS,
    items,
  };
};

export const clearSettlementDocumentProgress = (
  item: ISettlementDocumentItemProgress | null
): IClearSettlementDocumentProgress => {
  return {
    type: OrderTypeKeys.CLEAR_SETTLEMENT_DOCUMENT_PROGRESS,
    item,
  };
};

export const clearMultisiteSettlementDocumentProgress = (
  item: IMultisiteDocumentItem | null
): IClearMultisiteSettlementDocumentProgress => {
  return {
    type: OrderTypeKeys.MULTISITE_ORDER_CLEAR_SETTLEMENT_DOCUMENT_PROGRESS,
    item,
  };
};

export const clearGenerateReceiptProgress = (
  receiptMetaData: IDepositReceiptProgressMetaData | null
): IOrderClearReceiptProgress => {
  return {
    type: OrderTypeKeys.CLEAR_ORDER_DEPOSIT_RECEIPT_PROGRESS,
    receiptMetaData,
  };
};

export const orderProductsReceiveAction = (
  products: IOrderProduct[]
): IOrderProductsReceiveResultsAction => {
  return {
    type: OrderTypeKeys.RECEIVE_ORDER_PRODUCTS_RESULTS,
    products,
  };
};

export function getOrderProducts(fileId: string): AsyncAction<void> {
  return (dispatch) => {
    dispatch(
      setLoadingStateAction(OrderLoadingKeys.orderProductsLoading, true)
    );

    return workspaceAPI
      .get(`/files/${fileId}/order-details/products`)
      .then(({ data: products }) => {
        dispatch(orderProductsReceiveAction(products));
      })
      .catch((error) => {
        dispatch(orderErrorAction(error));
      })
      .finally(() => {
        dispatch(
          setLoadingStateAction(OrderLoadingKeys.orderProductsLoading, false)
        );
      });
  };
}

export const orderBusinesPartiesAction = (
  parties
): IOrderBusinessPartiesReceiveResultsAction => {
  return {
    type: OrderTypeKeys.RECEIVE_ORDER_BUSINESS_PARTIES_RESULTS,
    parties,
  };
};

export function getOrderBusinessParties(
  fileId: string | number
): AsyncAction<IOrderErrorAction | IOrderBusinessPartiesReceiveResultsAction> {
  return async (dispatch) => {
    dispatch(
      setLoadingStateAction(
        OrderLoadingKeys.orderTeamPanelBusinessPartiesLoading,
        true
      )
    );

    return await workspaceAPI
      .get(`/files/${fileId}/business-party-employees`)
      .then(({ data }) => dispatch(orderBusinesPartiesAction(data)))
      .catch((error) => dispatch(orderErrorAction(error)))
      .finally(() => {
        dispatch(
          setLoadingStateAction(
            OrderLoadingKeys.orderTeamPanelBusinessPartiesLoading,
            false
          )
        );
      });
  };
}

export const orderTitleProductionOfficesAction = (
  titleProductionOffices: IProductionOffice[]
): IOrderTitleProductionOfficesReceiveResultsAction => {
  return {
    type: OrderTypeKeys.RECEIVE_ORDER_TITLE_PRODUCTION_OFFICES_RESULTS,
    titleProductionOffices,
  };
};

export const orderEscrowProductionOfficesAction = (
  escrowProductionOffices: IProductionOffice[]
): IOrderEscrowProductionOfficesReceiveResultsAction => {
  return {
    type: OrderTypeKeys.RECEIVE_ORDER_ESCROW_PRODUCTION_OFFICES_RESULTS,
    escrowProductionOffices,
  };
};

export function getOrderProductionOffices(
  fileId: string,
  type: ProductionOfficeType
): AsyncAction<void> {
  return (dispatch) => {
    dispatch(
      setLoadingStateAction(
        type === ProductionOfficeType.TITLE
          ? OrderLoadingKeys.orderTeamPanelTitleOfficesLoading
          : OrderLoadingKeys.orderTeamPanelEscrowOfficesLoading,
        true
      )
    );

    return workspaceAPI
      .get(`/orders/${fileId}/production-offices/${type}`)
      .then(({ data }) => {
        if (type === ProductionOfficeType.TITLE) {
          dispatch(orderTitleProductionOfficesAction(data));
        }
        if (type === ProductionOfficeType.ESCROW) {
          dispatch(orderEscrowProductionOfficesAction(data));
        }
      })
      .catch((error) => {
        dispatch(orderErrorAction(error));
      })
      .finally(() => {
        dispatch(
          setLoadingStateAction(
            type === ProductionOfficeType.TITLE
              ? OrderLoadingKeys.orderTeamPanelTitleOfficesLoading
              : OrderLoadingKeys.orderTeamPanelEscrowOfficesLoading,
            false
          )
        );
      });
  };
}

export const orderPartiesAction = (
  orderParties
): IOrderPartiesReceiveResultsAction => {
  return {
    type: OrderTypeKeys.RECEIVE_ORDER_PARTIES_RESULTS,
    orderParties,
  };
};

export function getOrderParties(orderId: string | number) {
  return async (dispatch) => {
    dispatch(
      setLoadingStateAction(
        OrderLoadingKeys.orderTeamPanelOrderPartiesLoading,
        true
      )
    );

    return await workspaceAPI
      .get(`/orders/${orderId}/parties/${OrderPartyGroup.PRINCIPALS}`)
      .then(({ data }) => dispatch(orderPartiesAction(data)))
      .catch((error) => dispatch(orderErrorAction(error)))
      .finally(() => {
        dispatch(
          setLoadingStateAction(
            OrderLoadingKeys.orderTeamPanelOrderPartiesLoading,
            false
          )
        );
      });
  };
}

export const orderOtherPartiesAction = (
  otherParties: IOrderBusinessParty[]
): IOrderOtherPartiesReceiveResultsAction => {
  return {
    type: OrderTypeKeys.RECEIVE_ORDER_OTHER_PARTIES_RESULTS,
    otherParties,
  };
};

export function getOrderOtherParties(
  orderId: string | number
): AsyncAction<IOrderErrorAction | IOrderOtherPartiesReceiveResultsAction> {
  return (dispatch) => {
    dispatch(
      setLoadingStateAction(
        OrderLoadingKeys.orderTeamPanelOtherPartiesLoading,
        true
      )
    );

    return workspaceAPI
      .get(`/orders/${orderId}/parties/${OrderPartyGroup.OTHER}`)
      .then(({ data }) => dispatch(orderOtherPartiesAction(data)))
      .catch((error) => dispatch(orderErrorAction(error)))
      .finally(() => {
        dispatch(
          setLoadingStateAction(
            OrderLoadingKeys.orderTeamPanelOtherPartiesLoading,
            false
          )
        );
      });
  };
}
export function orderDocumentsErrorAction() {
  return {
    type: OrderTypeKeys.DOCUMENT_ERROR,
  };
}

export function setOrderDocuments(
  data: IDocument[],
  isDMSEnabled = false
): IOrderDocumentsAction {
  return {
    type: OrderTypeKeys.RECEIVE_ORDER_DOCUMENTS,
    documents: data,
    isDMSEnabled,
  };
}

export function getOrderDocuments(orderId: string) {
  return (dispatch) => {
    dispatch(
      setLoadingStateAction(OrderLoadingKeys.orderDocumentsLoading, true)
    );

    return workspaceAPI
      .get(`/orders/${orderId}/documents`)
      .then(({ data }) => {
        dispatch(
          setOrderDocuments(data.documents as IDocument[], data.isDMSEnabled)
        );
      })
      .catch(() => {
        dispatch(setOrderDocuments([]));
        dispatch(orderDocumentsErrorAction());
      })
      .finally(() => {
        dispatch(
          setLoadingStateAction(OrderLoadingKeys.orderDocumentsLoading, false)
        );
      });
  };
}

export function getOrderDocument(orderId: string, documentId: number) {
  return (dispatch) => {
    dispatch(
      setLoadingStateAction(OrderLoadingKeys.orderDocumentsLoading, true)
    );

    return workspaceAPI
      .get(`/orders/${orderId}/documents/${documentId}`)
      .then(({ data }) => {
        dispatch(setOrderDocuments([data] as IDocument[]));
      })
      .catch(() => {
        dispatch(setOrderDocuments([]));
        dispatch(orderDocumentsErrorAction());
      })
      .finally(() => {
        dispatch(
          setLoadingStateAction(OrderLoadingKeys.orderDocumentsLoading, false)
        );
      });
  };
}

export function fetchOrderAndDocuments(fileId): AsyncAction<void> {
  return async (dispatch) => {
    await dispatch(getOrderDetails(fileId));
    await dispatch(getOrderDocuments(fileId));
    await dispatch(getOrderLatestEpPackage(fileId));
  };
}

export function fetchOrderDocuments(fileId): AsyncAction<void> {
  return async (dispatch) => {
    await dispatch(getOrderDocuments(fileId));
    await dispatch(getOrderLatestEpPackage(fileId));
  };
}

export function fetchOrderDocumentContent(
  orderId: number,
  documentId: DocumentId,
  documentExtension: string,
  isDocumentInstance?: boolean
): AsyncAction<Blob> {
  return async (dispatch) => {
    const queryParams = new URLSearchParams();

    if (isDocumentInstance) {
      queryParams.append('isDocumentInstance', 'true');
    }

    const fileUrl = `/orders/${orderId}/documents/${documentId}/view?${queryParams}`;

    /**
     * TODO[epic=responseType: blob]: replace `workspaceAPI` with `client`, once the client supports `responseType: blob`.
     * https://github.com/ferdikoomen/openapi-typescript-codegen/pull/986
     */
    // client.orderDocuments
    //   .getDocumentContentAsync({
    //     orderId,
    //     documentId: String(documentId),
    //     isDocumentInstance,
    //   })

    return workspaceAPI
      .get(`${AppConfig.workspaceBackendUrl}${fileUrl}`, {
        responseType: 'blob',
      })
      .then(({ data }) => {
        const file = new Blob([data], {
          type: getDocumentContentType(documentExtension),
        });
        dispatch(setOrderDocumentContent(documentId, file));

        return file;
      });
  };
}

export function downloadDocument(
  orderId: number,
  documentId: DocumentId
): AsyncAction<Blob> {
  return async (dispatch) => {
    const fileUrl = `/orders/${orderId}/templateInstances/${documentId}/download`;

    return workspaceAPI
      .get(`${AppConfig.workspaceBackendUrl}${fileUrl}`, {
        responseType: 'blob',
      })
      .then(({ data }) => {
        const file = new Blob([data], {
          type: getDocumentContentType(DocumentExtensions.PDF),
        });
        dispatch(setOrderDocumentContent(documentId, file));

        return file;
      });
  };
}

export function fetchOrderDocumentTemplateContent(
  orderId: string | number,
  templateId: string,
  documentExtension: string
): AsyncAction<Blob> {
  return async (dispatch) => {
    /**
     * TODO[epic=responseType: blob]: replace `workspaceAPI` with `client`, once the client supports `responseType: blob`.
     * https://github.com/ferdikoomen/openapi-typescript-codegen/pull/986
     */
    // client.templates.previewAsync({
    //   orderId: Number(fileId),
    //   templateId: documentId,
    // });

    return workspaceAPI
      .get(`/templates/${templateId}/preview/${orderId}`, {
        responseType: 'blob',
      })
      .then(({ data }) => {
        const file = new Blob([data], {
          type: getDocumentContentType(documentExtension),
        });

        dispatch(setOrderDocumentContent(templateId, file));

        return file;
      });
  };
}

export function setOrderDocumentContent(
  documentId: DocumentId,
  file: Blob
): IOrderDocumentContentAction {
  return {
    type: OrderTypeKeys.RECEIVE_ORDER_DOCUMENT_CONTENT,
    documentId,
    file,
  };
}

/**
 * @deprecated Downloading single image documents should use downloadImageDocumentWithNotification which contains logic to display a toast notification with the new design specifications.
 */
export function downloadFastImageDocument(
  orderId: number,
  documentId: number,
  filename: string,
  fileSize: number
) {
  return async (dispatch) => {
    dispatch(setSingleDocDownloadId(documentId, fileSize));

    const fileUrl = `/files/${orderId}/images/${documentId}/contents?fileName=${filename}`;

    return workspaceAPI
      .get(`${AppConfig.workspaceBackendUrl}${fileUrl}`, {
        responseType: 'blob',
      })
      .then((result) => {
        downloadBlob(result.data, filename);
      })
      .catch((error) => {
        // if the file is too long to download --> timed out
        // then we present the user with the following message
        addToast(
          'There was an error downloading the document. Please try again later.',
          'error'
        )(dispatch);
        // the following dispatch clears loading so the user can continue with other tasks
        trackExceptions(error, {
          documentId,
          fileSize,
          orderId,
        });
        dispatch(orderErrorAction(error));
      })
      .finally(() => {
        dispatch(clearSingleDocDownloadId(documentId, fileSize));
      });
  };
}

// Future work: Investigate and identify how to switch logic using downloadFastImageDocument to this one.
// Download notifications should be consistent across the application.
export function downloadImageDocumentWithNotification(
  orderId: number,
  documentId: number,
  fileExtension: string,
  filename: string,
  fileSize: number
) {
  return async (dispatch) => {
    const requestId = uuidv4();
    dispatch(
      setSingleDownloadStartAction({
        id: requestId,
        documentId: String(documentId),
        name: filename,
        size: fileSize,
        extension: fileExtension,
      })
    );
    toast.custom(<DownloadSingleDocumentToast id={requestId} />, {
      toastId: requestId,
      autoClose: false,
    });

    const fileUrl = `/files/${orderId}/images/${documentId}/contents?fileName=${filename}`;

    return workspaceAPI
      .get(`${AppConfig.workspaceBackendUrl}${fileUrl}`, {
        responseType: 'blob',
      })
      .then((result) => {
        downloadBlob(result.data, filename);
        dispatch(setSingleDownloadSuccessAction(requestId));
      })
      .catch((error) => {
        dispatch(setSingleDownloadErrorAction(requestId));
        trackExceptions(error, {
          documentId,
          fileSize,
          orderId,
        });
      });
  };
}

export function downloadDocumentInstance(
  orderId: number,
  documentId: string,
  filename: string,
  fileSize: number
) {
  return async (dispatch: Dispatch) => {
    const requestId = uuidv4();
    dispatch(
      setSingleDownloadStartAction({
        id: requestId,
        documentId,
        name: filename,
        size: fileSize,
        extension: 'FAST',
      })
    );
    toast.custom(<DownloadSingleDocumentToast id={requestId} />, {
      toastId: requestId,
      autoClose: false,
    });

    return dispatch(downloadDocument(orderId, documentId))
      .then((fileBlob) => {
        downloadBlob(fileBlob, filename);
        dispatch(setSingleDownloadSuccessAction(requestId));
      })
      .catch((error) => {
        dispatch(setSingleDownloadErrorAction(requestId));
        // the following dispatch clears loading so the user can continue with other tasks
        trackExceptions(error, {
          documentId,
          fileSize,
          orderId,
        });
      });
  };
}

export function downloadCustomerDocument(
  orderId: string,
  documentId: number,
  filename: string
): AsyncAction<void> {
  return async (dispatch) => {
    const fileUrl = `/orders/${orderId}/customerdocuments/${documentId}/download`;

    return workspaceAPI
      .get(`${AppConfig.workspaceBackendUrl}${fileUrl}`, {
        responseType: 'blob',
      })
      .then((result) => {
        downloadBlob(result.data, filename);
      })
      .catch((error) => {
        // if the file is too long to download --> timed out
        // then we present the user with the following message
        addToast(
          'There was an error downloading the document. Please try again later.',
          'error'
        )(dispatch);
        // the following dispatch clears loading so the user can continue with other tasks
        dispatch(orderErrorAction(error));
      });
  };
}

/*
 * ############################################
 * [START] DOCUMENTS SHARING - Phase #1 Actions
 * ############################################
 */

export function documentPermissionsError(error) {
  return {
    type: OrderTypeKeys.DOCUMENT_PERMISSIONS_ERROR,
    error,
  };
}

export function fetchOrderDocumentsInfo(fileId: number) {
  return async (dispatch) => {
    await dispatch(getOrderDetails(String(fileId)));
    await dispatch(getOrderDocuments(String(fileId)));
  };
}

export function fetchOrderDocumentInfo(
  fileId: number,
  documentId: number
): AsyncAction<void> {
  return async (dispatch) => {
    await dispatch(getOrderDetails(String(fileId)));
    await dispatch(getOrderDocument(String(fileId), documentId));
  };
}

export function fetchOrderDocumentTemplateInfo(
  fileId: number | string,
  templateId: string
): AsyncAction<void> {
  return async (dispatch) => {
    try {
      await dispatch(getOrderDetails(String(fileId)));

      dispatch(
        setLoadingStateAction(OrderLoadingKeys.orderDocumentsLoading, true)
      );

      const response = await client.templates.searchTemplatesAsync({
        fileId: Number(fileId),
        requestBody: {
          text: templateId,
          isFullTextSearch: false,
          paging: {
            page: 1,
            pageSize: 1,
          },
        },
      });
      const template = response.data?.[0];

      if (!template || template.id !== templateId) {
        throw new Error('No template found');
      }

      const document = {
        displayName: template.description!,
        extension: DocumentExtensions.DMS,
        fileId,
        name: template.name,
        // `templateInstanceId` is used for compatibility with string ids.
        templateInstanceId: template.id,
      } as IDocument;

      dispatch(setOrderDocuments([document]));
    } catch (error) {
      dispatch(setOrderDocuments([]));
      dispatch(orderDocumentsErrorAction());
    } finally {
      dispatch(
        setLoadingStateAction(OrderLoadingKeys.orderDocumentsLoading, false)
      );
    }
  };
}

export function fetchOrderDocumentParties(
  fileId: string,
  documentIDs: number[]
) {
  return async (dispatch) => {
    await dispatch(getDocumentPartiesPermission(fileId, documentIDs));
    await dispatch(getDocumentPermissionCandidates(fileId, documentIDs));
  };
}

export function getDocumentPartiesPermission(
  fileId: string | number,
  documentIDs: number[]
): AsyncAction<void> {
  return async (dispatch) => {
    dispatch(
      setLoadingStateAction(
        OrderLoadingKeys.orderDocumentPartiesPermissionsLoading,
        true
      )
    );
    const idsUrl = getDocumentIdsURL(documentIDs);

    return await workspaceAPI
      .get(`/orders/${fileId}/documents/parties/authorized?${idsUrl}`)
      .then(({ data }) => {
        dispatch({
          type: OrderTypeKeys.RECEIVE_ORDER_DOCUMENT_PARTIES_WITH_PERMISSION,
          parties: data,
        });
        dispatch({ type: OrderTypeKeys.SUCCESS });
      })
      .catch((error) => {
        dispatch(documentPermissionsError(error));
      })
      .finally(() => {
        dispatch(
          setLoadingStateAction(
            OrderLoadingKeys.orderDocumentPartiesPermissionsLoading,
            false
          )
        );
      });
  };
}

export function getDocumentPermissionCandidates(
  fileId: string | number,
  documentIDs: number[]
): AsyncAction<void> {
  return async (dispatch) => {
    dispatch(
      setLoadingStateAction(
        OrderLoadingKeys.orderDocumentPermissionsLoading,
        true
      )
    );
    const idsUrl = getDocumentIdsURL(documentIDs);

    return await workspaceAPI
      .get(`/orders/${fileId}/documents/parties/available?${idsUrl}`)
      .then(({ data }) => {
        dispatch({
          type: OrderTypeKeys.RECEIVE_ORDER_DOCUMENT_PERMISSION_CANDIDATES,
          parties: data,
        });
        dispatch({ type: OrderTypeKeys.SUCCESS });
      })
      .catch((error) => {
        dispatch(documentPermissionsError(error));
      })
      .finally(() => {
        dispatch(
          setLoadingStateAction(
            OrderLoadingKeys.orderDocumentPermissionsLoading,
            false
          )
        );
      });
  };
}

export function revokeDocumentsPartyPermissions(
  fileId: number | string,
  documentIDs: number[],
  userId: number,
  email: string,
  name: string
): AsyncAction<void> {
  return async (dispatch) => {
    dispatch(
      setLoadingStateAction(
        OrderLoadingKeys.orderDocumentPermissionsLoading,
        true
      )
    );
    const idsUrl = getDocumentIdsURL(documentIDs);
    const url = `/orders/${fileId}/documents/permissions/${userId}?${idsUrl}`;

    return await workspaceAPI
      .delete(url)
      .then(() => {
        addToast(
          `Document permissions revoked for ${name} (${email}).`,
          'success'
        )(dispatch);
      })
      .catch((error) => {
        dispatch(documentPermissionsError(error));
      })
      .finally(() => {
        dispatch(
          setLoadingStateAction(
            OrderLoadingKeys.orderDocumentPermissionsLoading,
            false
          )
        );
      });
  };
}

interface IUserTarget {
  fileBusinessPartyId: number;
  sourceType?: OrderBusinessPartySourceType;
  userId?: number;
}

export function grantDocumentPartiesPermission(
  fileId: string,
  documentIds: number[],
  parties: IOrderBusinessParty[],
  sendNotification: boolean,
  optionalMessage: string,
  isEmailSendOnBehalfChecked: boolean,
  fromEmailName: string,
  fromEmailAddress: string
): AsyncAction<void> {
  return async (dispatch) => {
    const url = `/orders/${fileId}/documents/permissions`;
    const userTargets: IUserTarget[] = parties
      .map(({ partyId, userId, sourceType }: IOrderBusinessParty) => ({
        fileBusinessPartyId: partyId || 0,
        sourceType,
        userId,
      }))
      .filter((party) => party.fileBusinessPartyId !== 0);

    dispatch(
      setLoadingStateAction(
        OrderLoadingKeys.orderDocumentPermissionsLoading,
        true
      )
    );

    return await workspaceAPI
      .post(url, {
        documentIds,
        userTargets,
        sendNotification,
        optionalMessage,
        fromEmailName,
        fromEmailAddress,
        isEmailSendOnBehalfChecked,
      })
      .then(() => {
        const onBehalfToastMessage =
          isEmailSendOnBehalfChecked && fromEmailAddress.length > 0
            ? ` on behalf of ${fromEmailName} (${fromEmailAddress}).`
            : `.`;
        addToast(
          `${documentIds.length} ${
            documentIds.length === 1 ? 'document' : 'documents'
          } shared with ${parties.length} ${
            parties.length === 1 ? 'contact' : 'contacts'
          }${onBehalfToastMessage}`,
          'success'
        )(dispatch);
      })
      .catch((error) => {
        dispatch(documentPermissionsError(error));
      })
      .finally(() => {
        dispatch(
          setLoadingStateAction(
            OrderLoadingKeys.orderDocumentPermissionsLoading,
            false
          )
        );
      });
  };
}

export function addOrganizationContactToDocument(
  _fileId: number,
  _docId: number,
  party: IOrderBusinessParty
): AsyncAction<void> {
  return async (dispatch) => {
    dispatch(
      setLoadingStateAction(
        OrderLoadingKeys.orderDocumentPermissionsLoading,
        true
      )
    );

    // @TODO replace with actual path once back-end is implemented
    // workspaceApi.post(`/${fileId}/parties/${party.id}`)
    await mockRequest()
      .then(() => {
        dispatch({
          type: OrderTypeKeys.ADD_DOC_PARTY_TO_PARTIES_WITH_PERMISSIONS,
          party,
        });
      })
      .catch((error) => {
        dispatch(orderErrorAction(error));
      })
      .finally(() => {
        dispatch(
          setLoadingStateAction(
            OrderLoadingKeys.orderDocumentPermissionsLoading,
            false
          )
        );
      });
  };
}

interface INewOrganizationContactParams {
  docId: number;
  fileId: number;
  organizationId: number;
  organizationContactEmail: string;
  organizationContactFullName: string;
}

export function addNewOrganizationContactToDocument(
  formParams: INewOrganizationContactParams
): AsyncAction<IOrderErrorAction | void> {
  return async (dispatch, getState) => {
    const { documentOrganizations } = getState().orders;
    const organization = documentOrganizations[formParams.organizationId];
    const { role, city, state, phoneNumber } = organization;
    const party = {
      id: Number(new Date()),
      businessPartyRoleId: 54,
      businessPartyName: formParams.organizationContactFullName,
      email: formParams.organizationContactEmail,
      relatedBusinessPartyId: formParams.organizationId,
      role,
      city,
      state,
      phoneNumber,
    };

    dispatch(
      setLoadingStateAction(
        OrderLoadingKeys.orderDocumentPermissionsLoading,
        true
      )
    );

    // mock request to create a new organization contact should
    // return a contact object of `IOrderBusinessParty` type
    // workspaceApi.post(`/create-org-contact`, formParams);
    return await mockRequest()
      // .then(({ data }) => workspaceApi.post(`/grant-contact-doc-permissions`, orgId, contactId));
      .then(() => mockRequest(party))
      // returns a contact object of `IOrderBusinessParty` type
      .then(({ data }) => {
        dispatch({
          type: OrderTypeKeys.ADD_DOC_PARTY_TO_PARTIES_WITH_PERMISSIONS,
          party: data,
        });
      })
      .catch((error) => dispatch(orderErrorAction(error)))
      .finally(() => {
        dispatch(
          setLoadingStateAction(
            OrderLoadingKeys.orderDocumentPermissionsLoading,
            false
          )
        );
      });
  };
}

const mockRequest = (data?: any, delay = 1500) =>
  new Promise((resolve) => setTimeout(() => resolve({ data }), delay));

export function setOrderLatestEpPackage(
  latestEpPackage: IEagleProPackage | null
): IOrderGetLatestEpPackageSetLatestAction {
  return {
    type: OrderTypeKeys.EP_PACKAGE_SET_LATEST,
    package: latestEpPackage,
  };
}

export function setOrderEagleProPackagesError(
  error: AxiosError | null
): IOrderSetLatestEpPackageErrorAction {
  return {
    type: OrderTypeKeys.EP_PACKAGE_ERROR,
    error,
  };
}

export function getOrderLatestEpPackage(
  fileId: number
): AsyncAction<
  IOrderGetLatestEpPackageSetLatestAction | IOrderSetLatestEpPackageErrorAction
> {
  return (dispatch) => {
    dispatch(
      setLoadingStateAction(OrderLoadingKeys.orderLatestEpPackageLoading, true)
    );

    return workspaceAPI
      .get(`/orders/${fileId}/packages/_latest`)
      .then(({ data }) =>
        dispatch(setOrderLatestEpPackage(data as IEagleProPackage))
      )
      .catch((error) => dispatch(setOrderEagleProPackagesError(error)))
      .finally(() =>
        dispatch(
          setLoadingStateAction(
            OrderLoadingKeys.orderLatestEpPackageLoading,
            false
          )
        )
      );
  };
}

export function clearSingleDocDownloadId(
  documentId: DocumentId,
  fileSize: number
) {
  return (dispatch) => {
    const payload: IOrderClearSingleDocDownloadDocumentIdAction = {
      type: OrderTypeKeys.UI_CLEAR_SINGLE_DOC_DOWNLOAD_DOCUMENT_ID,
      documentId,
      fileSize,
    };
    dispatch(payload);
  };
}

export function setSingleDocDownloadId(
  documentId: DocumentId,
  fileSize: number
) {
  return (dispatch) => {
    const payload: IOrderSetSingleDocDownloadDocumentIdAction = {
      type: OrderTypeKeys.UI_SET_SINGLE_DOC_DOWNLOAD_DOCUMENT_ID,
      documentId,
      fileSize,
    };
    dispatch(payload);
  };
}

export function setUiMutliDocsOrderId(orderId: number) {
  return (dispatch) => {
    const payload: IOrderSetMultiDocsOrderIdAction = {
      type: OrderTypeKeys.UI_SET_MUTLI_DOCS_ORDER_ID,
      orderId,
    };

    dispatch(payload);
  };
}

export function setMultiDocumentIds(
  documentIds: number[],
  documentArchiveFilesize: number = 0
): ThunkAction<void, IApplicationState, any, AnyAction> {
  return (dispatch) => {
    const payload: IOrderSetMultiDocsDocumentIdsAction = {
      type: OrderTypeKeys.UI_SET_MUTLI_DOCS_DOCUMENT_IDS,
      documentIds,
      documentArchiveFilesize,
    };

    dispatch(payload);
  };
}

export function setUIMultiSiteSelection(siteFileIds: number[]) {
  return (dispatch) => {
    const payload: IOrderSetMultisiteSelectionAction = {
      type: OrderTypeKeys.UI_SET_MULTISITE_SELECTION,
      siteFileIds,
    };

    dispatch(payload);
  };
}

export function setUiMultipleDocsDownloadPrompt(show: boolean) {
  return (dispatch) => {
    const payload: IOrderSetMultiDocsDownloadPromptVisibilityAction = {
      type: OrderTypeKeys.UI_SET_MUTLI_DOCS_PROMPT_VISIBILITY,
      show,
    };

    dispatch(payload);
  };
}

export function setUiDocumentPermissionsContactInputIds(ids: string[]) {
  return (dispatch) => {
    const payload: IOrderSetDocPermissionsContactInputIdsAction = {
      type: OrderTypeKeys.UI_SET_DOC_PERMISSIONS_CONTACT_INPUT_IDS,
      ids,
    };

    dispatch(payload);
  };
}

export function downloadMultipleDocuments(
  fileId: number,
  documentIds: number[],
  fileNumber: string
): AsyncAction<void> {
  return (dispatch) => {
    dispatch(
      setLoadingStateAction(OrderLoadingKeys.orderDocumentsLoading, true)
    );

    return workspaceAPI
      .post(`/orders/${fileId}/documents/download`, documentIds, {
        responseType: 'blob',
      })
      .then(({ data }) => {
        const blob = new Blob([data], { type: 'application/zip' });
        const suffix = new Date().toISOString().substr(0, 10).replace(/-/g, '');
        const filename = `${slugify(
          fileNumber
        )}_documents_clarityfirst_${suffix}.zip`;

        downloadBlob(blob, filename);
        dispatch(setMultiDocumentIds([]));
      })
      .catch((error) => {
        addToast(
          'There was an error downloading one or more of the documents. Please try again later.',
          'error'
        )(dispatch);
        dispatch(orderErrorAction(error));
      })
      .finally(() => {
        dispatch(setUiMultipleDocsDownloadPrompt(false));
        dispatch(
          setLoadingStateAction(OrderLoadingKeys.orderDocumentsLoading, false)
        );
      });
  };
}

export function removeOrderPartyContact(
  orderId: number,
  contactId: number,
  onSuccess: () => void
): AsyncAction<AxiosInstance> {
  return async (dispatch) => {
    dispatch(
      setLoadingStateAction(OrderLoadingKeys.orderTeamPanelLoading, true)
    );

    return await workspaceAPI
      .delete(`/orders/${orderId}/parties/contacts/${contactId}`)
      .then(async () => {
        dispatch({
          type: OrderTypeKeys.REMOVE_ORDER_PARTY_CONTACT,
          contactId,
        });
        await onSuccess();
      })
      .catch((error) => error)
      .finally(() => {
        dispatch(
          setLoadingStateAction(OrderLoadingKeys.orderTeamPanelLoading, false)
        );
      });
  };
}

export function revokeOrderAccess(
  orderId: number,
  partyid: number,
  onSuccess: () => void
): AsyncAction<AxiosInstance> {
  return async (dispatch) => {
    dispatch(
      setLoadingStateAction(OrderLoadingKeys.orderTeamPanelLoading, true)
    );

    return await workspaceAPI
      .put(`/orders/${orderId}/parties/contacts/${partyid}/_revoke`)
      .then(async () => {
        dispatch(getOrderParties(orderId));
        dispatch(getOrderOtherParties(orderId));
        await onSuccess();
      })
      .catch((error) => error)
      .finally(() => {
        dispatch(
          setLoadingStateAction(OrderLoadingKeys.orderTeamPanelLoading, false)
        );
      });
  };
}

export function grantOrderAccess(
  orderId: number,
  partyId: number,
  onSuccess: () => void
): AsyncAction<AxiosInstance> {
  return async (dispatch) => {
    dispatch(
      setLoadingStateAction(OrderLoadingKeys.orderTeamPanelLoading, true)
    );

    return await workspaceAPI
      .put(`/orders/${orderId}/parties/contacts/${partyId}/_grant`)
      .then(async () => {
        dispatch(getOrderParties(orderId));
        dispatch(getOrderOtherParties(orderId));
        await onSuccess();
      })
      .catch((error) => error)
      .finally(() => {
        dispatch(
          setLoadingStateAction(OrderLoadingKeys.orderTeamPanelLoading, false)
        );
      });
  };
}

export function removeOrderPartyEmployee({
  orderId,
  employeeId,
  roleId,
  onSuccess,
  onError,
}: {
  orderId: number;
  employeeId: number;
  roleId: number;
  onSuccess: () => void;
  onError: () => void;
}): AsyncAction<void> {
  return async (dispatch) => {
    return await workspaceAPI
      .delete(`/orders/${orderId}/employees/${employeeId}/roles/${roleId}`)
      .then(async () => {
        dispatch({
          type: OrderTypeKeys.REMOVE_ORDER_PARTY_EMPLOYEE,
          employeeId,
          roleId,
        });
        await onSuccess();
      })
      .catch(async () => {
        if (onError) await onError();
      });
  };
}

export function IngestFileByOrderNumber(query: string): AsyncAction<void> {
  return async (dispatch) => {
    const url = `/orders/${query}`; // files-to-link
    dispatch({ type: OrderTypeKeys.REQUEST });

    return await workspaceAPI
      .put(url)
      .then((response) => {
        if (response.status >= 300) {
          throw Error(response.statusText);
        }
        dispatch({
          type: OrderTypeKeys.SUCCESS,
        });
        dispatch(
          showNotification(
            {
              title: 'Order Ingestion process has started successfully.',
              ...notificationTheme.success,
            },
            true
          )
        );
      })
      .catch((error) => {
        dispatch({
          type: OrderTypeKeys.ERROR,
          error,
        });
        const errorMessage = 'No match found for the Order number';
        if (error.response && error.response.status === FILE_NOT_FOUND) {
          dispatch(
            showNotification(
              {
                title: errorMessage,
                ...notificationTheme.alert,
              },
              true
            )
          );
        } else {
          dispatch({
            type: OrderTypeKeys.ERROR,
            error,
          });
          dispatch(
            showNotification({
              title: 'Some Error Occured',
              ...notificationTheme.alert,
            })
          );
        }
      });
  };
}

export const clearSearchIngestOrder = () => {
  return (dispatch) => {
    dispatch({ type: OrderTypeKeys.CLEAR });
  };
};

export const setBulkUpdatesStatusUpdates = (
  siteOrders: IBulkUpdateStatus[],
  isLoanAmountAllocation: boolean = false,
  fetchOrderDetailsOnFinish: boolean = false
): ISetBulkUpdatesStatusProgress => {
  return {
    type: OrderTypeKeys.SET_BULK_UPDATES_STATUS_PROGRESS,
    bulkUpdatesStatusProgress: {
      siteOrders,
      isLoanAmountAllocation,
      fetchOrderDetailsOnFinish,
    },
  };
};

export const setDeleteOrderLoanProgress = (
  items: IBulkUpdateStatus[],
  fetchOrderDetailsOnFinish = false
) => ({
  type: OrderTypeKeys.SET_DELETE_ORDER_LOAN_STATUS_PROGRESS,
  deleteOrderLoanProgress: {
    items,
    fetchOrderDetailsOnFinish,
  },
});

export const getDepositsForMilestones = (
  orderId: string,
  includedStatuses?: string[],
  showToast = true
): AsyncAction<void> => {
  return (dispatch) => {
    dispatch(
      setLoadingStateAction(OrderLoadingKeys.orderDepositsLoading, true)
    );

    return workspaceAPI
      .get<IMilestoneDeposit[]>(
        `/orders/${orderId}/deposits/deposit-milestones`
      )
      .then(({ data }) => {
        const milestoneDeposits = data.map(({ issueDate, ...deposit }) => ({
          ...deposit,
          // issueDate comes over the wire as a string. We need to hydrate
          // this into a JS Date object
          issueDate: issueDate ? new Date(issueDate) : null,
        }));

        dispatch({
          type: OrderTypeKeys.REQUEST_ORDER_MILESTONE_DEPOSITS_SUCCESS,
          milestoneDeposits: includedStatuses?.length
            ? milestoneDeposits.filter((d) =>
                includedStatuses.includes(d.status)
              )
            : milestoneDeposits,
        });
      })
      .catch((error) => {
        dispatch({
          type: OrderTypeKeys.REQUEST_ORDER_MILESTONE_DEPOSITS_ERROR,
          error,
        });
        if (showToast)
          addToast(
            'There was an error loading earnest money deposits.',
            'error'
          )(dispatch);
      })
      .finally(() => {
        dispatch(
          setLoadingStateAction(OrderLoadingKeys.orderDepositsLoading, false)
        );
      });
  };
};
