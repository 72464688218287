import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { blue, black, trout, footerHeight, headerHeight } from 'config/theme';
import * as ErrorGraphics from './ErrorGraphics';

export enum ErrorCode {
  ACCOUNT_DEACTIVATED = '403',
  NOT_FOUND = '404',
  UNKNOWN_ERROR = '500',
  SECURITY_ERROR = '417',
  SECURITY_EXPIRED = '418',
}

const CODE_GRAPHICS = {
  [ErrorCode.ACCOUNT_DEACTIVATED]: ErrorGraphics.UnknownErrorGraphic,
  [ErrorCode.NOT_FOUND]: ErrorGraphics.NotFoundGraphic,
  [ErrorCode.UNKNOWN_ERROR]: ErrorGraphics.UnknownErrorGraphic,
  [ErrorCode.SECURITY_ERROR]: ErrorGraphics.UnknownErrorGraphic,
  [ErrorCode.SECURITY_EXPIRED]: ErrorGraphics.Lock,
};

interface IErrorPageWithGraphicProps {
  actionsComponent?: React.ReactNode;
  icon?: React.ReactNode;
  statusCode?: ErrorCode;
  description: string | React.ReactNode;
  showCode?: boolean;
  title: string | React.ReactNode;
  titleSize?: string;
  titleStyles?: CSSProperties;
  statusCodeStyles?: CSSProperties;
  className?: string;
}

export default ({
  actionsComponent,
  icon,
  statusCode,
  description,
  showCode = true,
  title,
  titleSize = '56px',
  titleStyles,
  statusCodeStyles,
  className,
}: IErrorPageWithGraphicProps) => (
  <Container className={className}>
    <Graphic svg={icon || (statusCode ? CODE_GRAPHICS[statusCode] : null)} />
    <Title titleSize={titleSize} style={titleStyles}>
      {title}
    </Title>
    {actionsComponent}
    <Description>{description}</Description>
    {showCode && <Code style={statusCodeStyles}>Error Code: {statusCode}</Code>}
  </Container>
);

const Container = styled.div`
  height: calc(100vh - ${2 * (headerHeight + footerHeight)}px);
  max-width: 740px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 90px;
`;

export const Title = styled.h1<{ titleSize: string }>`
  color: ${blue};
  font-size: ${({ titleSize }) => titleSize};
  font-weight: normal;
  line-height: 72px;
  margin-top: 34px;
  text-align: center;
  width: 100%;
`;

const Description = styled.div`
  color: ${black};
  font-size: 16px;
  font-weight: 350;
  line-height: 24px;
  margin: 0px auto;
  text-align: center;
`;

const Code = styled.div`
  color: ${trout};
  font-size: 12px;
  line-height: 16px;
  text-align: center;
`;

interface IGraphicProps {
  svg: any;
}

// Wrap svg component to a div element to avoid unwanted
// scaling in IE11.
const Graphic = ({ svg: Svg }: IGraphicProps) =>
  Svg ? (
    <div>
      <Svg />
    </div>
  ) : null;
