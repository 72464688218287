import React, { useMemo } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { toast } from 'components/Snackbar';
import AccordionSnackbar from 'components/Snackbar/AccordionSnackbar';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { SUB_TEXT } from 'config/typography';
import { red8 } from 'config/colors';
import DownloadSingleDocumentToastRow from './DownloadSingleDocumentToastRow';
import { setSingleDownloadClearAction } from 'store/documents/actions';

const DocumentCreateToast = ({ id }: { id: string }) => {
  const downloadUi = useAppSelector((state) => state.documents.downloadUi);
  const dispatch = useAppDispatch();
  const headerLabel = useMemo(() => {
    if (downloadUi[id]?.status === 'loading') return 'Downloading Document...';
    if (
      downloadUi[id]?.status === 'success' ||
      downloadUi[id]?.status === 'error'
    )
      return 'Downloaded Document';
    return '';
  }, [downloadUi]);

  const handleClose = () => {
    toast.dismiss(id);
    dispatch(setSingleDownloadClearAction(id));
  };

  if (!downloadUi[id]) {
    return null;
  }

  return (
    <AccordionSnackbar
      headerLabel={headerLabel}
      subtext={
        downloadUi[id].status === 'error' ? (
          <ErrorSubtext>Something went wrong. Please try again.</ErrorSubtext>
        ) : undefined
      }
      onClose={downloadUi[id].status === 'loading' ? undefined : handleClose}
    >
      <DownloadSingleDocumentToastRow
        {...downloadUi[id]}
        extension={downloadUi[id].extension}
      />
    </AccordionSnackbar>
  );
};

const ErrorSubtext = styled.p`
  ${SUB_TEXT}
  color: ${red8}
`;

export default connect()(DocumentCreateToast);
