import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Column, Row } from 'components/ui/Box';
import FileIcon, { fileTypeForExtension } from 'components/svg/FileType';
import {
  gray10,
  gray8,
  green8,
  primaryBlue8,
  red8,
  redGhost1,
} from 'config/colors';
import AlertCircle from 'icons/AlertCircle';
import {
  ELLIPSIS_TEXT,
  SMALL_BODY_MEDIUM,
  SUB_TEXT_BOOK,
} from 'config/typography';
import { Spinner } from 'components/ui/Spinner/Spinner';
import { getValueOrDefault } from 'utils/text';
import { IDownloadDocumentState } from 'store/documents/types';
import CheckCircle from 'icons/CheckCircle';

interface IDownloadSingleDocumentToastRowProps
  extends Omit<IDownloadDocumentState, 'type' | 'documentId' | 'id'> {
  extension: string;
}

const DownloadSingleDocumentToastRow = ({
  name,
  extension,
  size,
  status,
}: IDownloadSingleDocumentToastRowProps) => {
  const getSizeText = useMemo(() => {
    if (!size) {
      return '';
    }

    return `${getValueOrDefault(String(size))}K`;
  }, [size]);

  return (
    <StyledRow
      error={status === 'error'}
      dataTestId="DownloadSingleDocumentToastRow"
    >
      <DetailContainer>
        <FileIcon
          type={fileTypeForExtension(extension?.replace('.', '') || '')}
          style={{ height: '26px', width: '23px', marginRight: '8px' }}
        />
        <Details>
          <DocName data-testid="documentName">
            {getValueOrDefault(name)}
          </DocName>
          <FileDetail>{getSizeText}</FileDetail>
        </Details>
      </DetailContainer>
      <div>
        {status === 'loading' && (
          <Spinner
            data-testid="documentDownloadLoading"
            size={26}
            color={primaryBlue8}
            style={{ marginTop: '4px' }}
          />
        )}
        {status === 'error' && (
          <span data-testid="documentDownloadError">
            <AlertCircle fill={red8} />
          </span>
        )}
        {status === 'success' && (
          <span data-testid="documentDownloadSuccess">
            <CheckCircle fill={green8} />
          </span>
        )}
      </div>
    </StyledRow>
  );
};

const StyledRow = styled(Row)<{ error: boolean }>`
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  ${({ error }) =>
    error
      ? css`
          background-color: ${redGhost1};
        `
      : ''}
`;

const DocName = styled.p`
  ${SMALL_BODY_MEDIUM}
  ${ELLIPSIS_TEXT}
  white-space: nowrap;
  color: ${gray10};
`;

const FileDetail = styled.p`
  ${SUB_TEXT_BOOK}
  color: ${gray8}
`;

const DetailContainer = styled(Row)`
  align-items: center;
  justify-content: center;
`;

const Details = styled(Column)`
  width: 160px;
`;

export default DownloadSingleDocumentToastRow;
