import { hot } from 'react-hot-loader/root';
import React from 'react';
import { Helmet } from 'react-helmet';
import Favicon from 'react-favicon';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import { ThroughProvider } from 'react-through';
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
} from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import { UserType } from 'store/auth/types';
import { isFeatureEnabled } from 'store/features/helpers';

import ScrollToTop from './components/ScrollToTop';
import ContentContainer from 'components/ContentContainer';
import Header from './components/Header';
import Breadcrumbs from './components/Breadcrumbs';
import ViewLoader from './components/ViewLoader';
import Footer from './components/Footer';
import { SessionTimeoutModal } from 'components/SessionTimeoutModal';
import ClarityfirstVideoModal from 'components/Header/ClarityFirstVideoModal';

import { InternalError } from './views/Error';

import {
  minPageWidth,
  flexboxGridTheme,
  persianRed,
  blue as cfBlue,
  black as cfBlack,
  trout,
} from 'config/theme';
import { ActivityRightValue } from 'store/permissions/types';
import { canUserAccessFeature } from 'store/permissions/helpers';

import { MultisiteFeatures } from 'store/features/types';
import { extractQueryParams } from 'utils/url';
import { processEmailCode } from 'store/users/actions';
import { IApplicationState } from 'store';
import OrderDocumentUploadProgress from 'components/OrderDocuments/OrderDocumentUploadProgress';

import loadable from '@loadable/component';
import DepositReceiptGenerationProgress from 'components/Deposits/DepositReceiptGenerationProgress';
import IssueCheckDocumentGenerationProgress from 'components/Disbursements/IssueCheckDocumentGenerationProgress';
import SettlementDocumentGenerationProgress from 'Orders/Settlement/Export/SettlementDocumentGenerationProgress';
import { ErrorBoundary } from 'Errors/ErrorBoundary';
import { BulkUpdatesStatusProgress } from 'components/MultiSiteBulkUpdates/Shared';
import DeleteLoanProgress from 'views/OrderLoans/DeleteLoanModal/DeleteLoanProgress';
import MultisiteDocumentGenerationProgress from 'Orders/Settlement/Multisite/Export/MultisiteDocumentGenerationProgress';
import BuildTrustExtractStatusProgress from 'views/Accounting/TrustAccounting/BuildTrustExtractStatusProgress';
import * as FullStory from '@fullstory/browser';
import { TrustExtractProgressBar } from 'views/Accounting/TrustAccounting/TrustExtractProgressBars';
import { DepositCheckProgressBar } from 'components/Deposits/DepositCheckProgressBar';

const LoadableOptions = { fallback: <ViewLoader type="line-scale" active /> };
const InternalSite = loadable(
  () => import('./sites/InternalSite'),
  LoadableOptions
);
const ExternalSite = loadable(
  () => import('./sites/ExternalSite'),
  LoadableOptions
);
const AdminSite = loadable(() => import('./sites/AdminSite'), LoadableOptions);
const AdminUserReadOnlySite = loadable(
  () => import('./sites/AdminUserReadOnlySite'),
  LoadableOptions
);

const muiTheme = createTheme({
  palette: {
    error: {
      main: persianRed,
    },
    primary: {
      main: cfBlue,
    },
    text: {
      primary: cfBlack,
      secondary: trout,
    },
  },
  typography: {
    fontFamily: 'Circular, sans-serif',
  },
});

interface IAppProps {
  userFirstName: string;
  userLastName: string;
  userEmail: string;
  userAuthenticated: boolean;
  userId: number;
  primaryOfficeName: string;
  loading: boolean;
  isAdmin: boolean;
  userType: UserType;
  location: any;
  isSupportAdmin: boolean;
  isAdminBulkExternalInvitesEnabled: boolean;
  isMultisiteMVPEnabled: boolean;
  footerStyleOverrides: React.CSSProperties;
  processEmailCode(emailCode: string): void;
}

export class App extends React.Component<IAppProps> {
  public componentDidMount() {
    const {
      values: { emailCode },
      restSearchParams,
    } = extractQueryParams(location.search, ['emailCode']);
    if (emailCode) {
      this.props.processEmailCode(emailCode);
      const newURL = `${location.pathname}${restSearchParams}`;
      history.replaceState(null, '', newURL);
    }
  }

  public blockedUser = (email) => {
    const blockedUsers = AppConfig.fullstoryBlockedUsers?.split(',') || [];
    return blockedUsers.includes(email);
  };

  public render() {
    const { loading, userType } = this.props;
    const { from } = this.props.location.state || { from: { pathname: '/' } };

    if (loading) {
      return <ViewLoader type="line-scale" active />;
    }

    if (userType === UserType.Unregistered) {
      return <InternalError />;
    }

    if (from && from.pathname !== '/') {
      return <Redirect to={from} />;
    }

    if (AppConfig.fullstoryOrgid && FullStory !== undefined) {
      FullStory.init({ orgId: AppConfig.fullstoryOrgid.toString() });
      localStorage.removeItem('roles');
    }

    return (
      <>
        {AppConfig?.whatfixUrl && (
          <Helmet>
            <link rel="preconnect" href={AppConfig.whatfixUrl} />
            <script src={AppConfig.whatfixUrl} async type="text/javascript" />
          </Helmet>
        )}
        <MUIThemeProvider theme={muiTheme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <ThemeProvider theme={flexboxGridTheme}>
              <ThroughProvider>
                <ScrollToTop>{this.renderPage()}</ScrollToTop>
              </ThroughProvider>
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </MUIThemeProvider>
      </>
    );
  }

  private renderPage() {
    const {
      isAdmin,
      userType,
      isSupportAdmin,
      isAdminBulkExternalInvitesEnabled,
      isMultisiteMVPEnabled,
      userFirstName,
      userLastName,
      userEmail,
      userAuthenticated,
      userId,
      primaryOfficeName,
    } = this.props;
    const isInternalUser = userType === UserType.Internal;

    return (
      <Page>
        <StickyContent>
          <Favicon url="/favicon.ico" />
          <Header />
          <ClarityfirstVideoModal />
          <ContentContainer>
            <Breadcrumbs />
          </ContentContainer>
          <ErrorBoundary isInternalUser={isInternalUser}>
            <Switch>
              {AppConfig.fullstoryOrgid &&
                !!userId &&
                !this.blockedUser(userEmail) &&
                FullStory.identify(userId.toString(), {
                  displayName: userEmail,
                  email: userEmail,
                  'First Name': userFirstName,
                  'Last Name': userLastName,
                  'User Type': userType,
                  'Primary Office': primaryOfficeName,
                  Authenticated: userAuthenticated,
                })}
              {AppConfig.fullstoryOrgid &&
                !!userId &&
                this.blockedUser(userEmail) &&
                FullStory.shutdown()}

              {isAdmin && <Route path="/admin" component={AdminSite} />}

              {(isSupportAdmin || isAdminBulkExternalInvitesEnabled) && (
                <Route path="/admin" component={AdminUserReadOnlySite} />
              )}

              {isInternalUser && (
                <Route
                  render={(props) => (
                    <InternalSite
                      multisiteMvpEnabled={isMultisiteMVPEnabled}
                      {...props}
                    />
                  )}
                />
              )}

              {!isInternalUser && (
                <Route
                  render={(props) => (
                    <ExternalSite
                      multisiteMvpEnabled={isMultisiteMVPEnabled}
                      {...props}
                    />
                  )}
                />
              )}
            </Switch>
          </ErrorBoundary>
          <ProgressArea
            bottomOffset={
              this.props.footerStyleOverrides?.paddingBottom ? 70 : 0
            }
          >
            <OrderDocumentUploadProgress />
            <DepositReceiptGenerationProgress />
            <IssueCheckDocumentGenerationProgress />
            <MultisiteDocumentGenerationProgress />
            <SettlementDocumentGenerationProgress />
            <BulkUpdatesStatusProgress />
            <DeleteLoanProgress />
            <BuildTrustExtractStatusProgress />
            <TrustExtractProgressBar />
            <DepositCheckProgressBar />
          </ProgressArea>
        </StickyContent>

        <Footer />
        <SessionTimeoutModal />
      </Page>
    );
  }
}

export function mapStateToProps(state: IApplicationState) {
  const isAdmin = canUserAccessFeature({
    state,
    acceptedActivityRights: [
      ActivityRightValue.Admin,
      ActivityRightValue.UserManagement,
    ],
  });

  const isSupportAdmin = canUserAccessFeature({
    state,
    acceptedActivityRights: [ActivityRightValue.SupportAdmin],
  });
  const isAdminBulkExternalInvitesEnabled = canUserAccessFeature({
    state,
    acceptedActivityRights: [ActivityRightValue.BulkExternalInvite],
  });
  const isMultisiteMVPEnabled = isFeatureEnabled(
    state,
    MultisiteFeatures.MultisiteMVP
  );
  return {
    loading: state.auth.loading,
    isAdmin,
    userType: state.auth.session.type,
    isSupportAdmin,
    isAdminBulkExternalInvitesEnabled: isAdminBulkExternalInvitesEnabled,
    isMultisiteMVPEnabled,
    footerStyleOverrides: state.ui.footerStyleOverrides,
    userFirstName: state.auth.session.firstName,
    userLastName: state.auth.session.lastName,
    userEmail: state.auth.session.email,
    userAuthenticated: state.auth.session.authenticated,
    userId: state.auth.session.userId,
    primaryOfficeName: state.auth.session.primaryOfficeName,
  };
}

const mapDispatchToProps = (dispatch) => ({
  processEmailCode(emailCode: string) {
    return dispatch(processEmailCode(emailCode));
  },
});

// Okay to use hot() call below for both dev and prod environments, since
// RHL itself does the differentiation. If NODE_ENV === 'production', it
// amounts to a no-op, directly returning whatever what is passed to it.
export default hot(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
);

const Page = styled.div`
  min-width: ${minPageWidth}px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StickyContent = styled.div`
  flex: 1 0 auto;
`;

const ProgressArea = styled.div<{ bottomOffset: number }>`
  display: flex;
  position: fixed;
  bottom: ${(props) => 32 + props.bottomOffset}px;
  right: 32px;
  z-index: 100;
`;
