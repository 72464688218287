import moment, { MomentInput } from 'moment-timezone';
import { isEmpty, pluralize } from './text';

export enum dateFormat {
  MMMDYYYY = 'MMM D, YYYY',
  MMDDYYYY = 'MM/DD/YYYY',
  MMDDYYYYDASH = 'MM-DD-YYYY',
  default = 'M/D/YYYY',
  MMMMDOYYYY = 'MMMM Do, YYYY',
  MMMMDO = 'MMMM Do',
  YYYYMMDD = 'YYYY-MM-DD',
  MMDDYYYYATHMMA = 'MM/DD/YYYY [at] h:mm A',
  MMDDYYYYHHMMSSA = 'MM/DD/YYYY hh:mm:ss A',
  MDYYYYHHMMA = 'M/D/YYYY h:mm a',
  LVERTICALBARHHMMA = 'L | hh:mm A',
  HMMA = 'h:mma',
  HMMSSA = 'h:mm:ssa',
  LLLL = 'LLLL',
  LTS = 'LTS',
  LT = 'LT',
}

export const formatDate = (
  date: string | null | undefined,
  locale: string = 'en-US',
  localization: boolean = true
) => {
  if (!date) return;
  if (!localization) {
    const dateParts = date.split('T')[0].split('-');
    return (
      parseInt(dateParts[1], 10) +
      '/' +
      parseInt(dateParts[2], 10) +
      '/' +
      dateParts[0]
    );
  }
  return new Date(date).toLocaleDateString(locale);
};

export const formatLocalDate = (
  date: Date | string | null | undefined,
  format: dateFormat = dateFormat.default
) => {
  if (!date) return;

  return moment.utc(date).local().format(format);
};

export const getCurrentFullYear = () => new Date().getFullYear();

export const formatDateTo = (
  date?: Date | string | null,
  format: string = dateFormat.default,
  locale: string = 'en-US',
  localization: boolean = true
) => {
  if (date && !localization) {
    return moment(date).format(format);
  } else if (date) {
    return moment(date).locale(locale).format(format);
  }
  return null;
};

export const validateAndFormatDate = (
  dateString: string | null,
  locale: string = 'en-US',
  format: string = dateFormat.default
): string => {
  const datePatterns = [
    /^\d{1,2}-\d{1,2}-\d{4}$/,
    /^\d{1,2}\/\d{1,2}\/\d{4}$/,
    /^\d{1,2}\.\d{1,2}\.\d{4}$/,
  ];

  const isValid = datePatterns.some((pattern) =>
    pattern.test(dateString || '')
  );

  if (!isValid) {
    return 'Invalid Date';
  }
  return moment(dateString).locale(locale).format(format);
};

export const areDatesEqual = (
  date1?: Date | null | undefined,
  date2?: Date | null | undefined
): boolean => {
  const dateString1 = formatDateTo(date1, dateFormat.YYYYMMDD, 'en-US', false);
  const dateString2 = formatDateTo(date2, dateFormat.YYYYMMDD, 'en-US', false);

  return dateString1 === dateString2;
};

export const normalizeDate = (date: Date | string) =>
  moment(date).format('YYYY-MM-DDT00:00:00.000') + 'Z';

export const normalizeDateToPT = (date: Date | string) =>
  moment(date).format('MM/DD/YYYY hh:mm:ss A') + ' PT';

export const getTodayDate = (date: string = new Date().toString()) => {
  return moment(date).format('MM/DD/YYYY');
};

export const formatUtcDateToPt = (date: Date) => {
  return (
    moment.utc(date).tz('America/Los_Angeles').format('MM/DD/YYYY - hh:mm a') +
    ' PT'
  );
};

export const timestampToString = (timestamp: string): string => {
  if (isEmpty(timestamp)) {
    return '';
  }

  const date = moment(Date.parse(timestamp + '+00:00'));
  return moment(date).format('MMMM Do, YYYY [at] h:mma');
};

export const PLAIN_DATE_FORMAT = 'MMMM Do, YYYY [at] h:mma';

export const timeInWords = (
  dateInput: string | Date,
  capitalize = true
): string => {
  if (
    (typeof dateInput !== 'string' && typeof dateInput !== 'object') ||
    !dateInput ||
    (typeof dateInput === 'string' && isEmpty(dateInput))
  )
    return 'unknown';

  const date = moment(
    typeof dateInput === 'string' ? Date.parse(dateInput + '+00:00') : dateInput
  );
  const now: Date = new Date(Date.now());
  const secondsDiff = moment(now).diff(date, 'seconds');

  let words = moment(date).format('MMMM Do [at] h:mma');

  if (secondsDiff < 0) {
    return words;
  }

  const hoursDiff = Math.floor(secondsDiff / 3600);
  const yearsDiff = moment(date).diff(now, 'years');
  const daysDiff = moment(now)
    .startOf('days')
    .diff(moment(date).startOf('days'), 'days');

  if (secondsDiff <= 60) {
    words = `${capitalize ? 'J' : 'j'}ust now`;
  } else if (hoursDiff < 1) {
    const minutes = Math.floor(secondsDiff / 60);
    words = `${minutes} ${pluralize(minutes, 'minute')} ago`;
  } else if (hoursDiff < 12) {
    words = `${hoursDiff} ${hoursDiff === 1 ? 'hour' : 'hours'} ago`;
  } else if (daysDiff <= 6) {
    const time = moment(date).format('h:mma');

    if (daysDiff === 0) {
      words = `${capitalize ? 'T' : 't'}oday at ${time}`;
    } else if (daysDiff === 1) {
      words = `${capitalize ? 'Y' : 'y'}esterday at ${time}`;
    } else {
      words = moment(date).format('dddd [at] h:mma');
    }
  } else if (yearsDiff < 0) {
    words = moment(date).format(PLAIN_DATE_FORMAT);
  }

  return words;
};

export const timeInWordsIncludingYearFormat = (
  dateInput: string | Date,
  capitalize = true
): string => {
  if (
    (typeof dateInput !== 'string' && typeof dateInput !== 'object') ||
    !dateInput ||
    (typeof dateInput === 'string' && isEmpty(dateInput))
  )
    return 'unknown';

  const date = moment(
    typeof dateInput === 'string' ? Date.parse(dateInput + '+00:00') : dateInput
  );
  const now: Date = new Date(Date.now());
  const secondsDiff = moment(now).diff(date, 'seconds');

  let words = moment(date).format('MMMM D [at] h:mma');

  if (secondsDiff < 0) {
    return words;
  }

  const hoursDiff = Math.floor(secondsDiff / 3600);
  const yearsDiff = moment(date).diff(now, 'years');
  const currentYear = moment().year();
  const updateYear = moment(date).year();

  const daysDiff = moment(now)
    .startOf('days')
    .diff(moment(date).startOf('days'), 'days');

  if (secondsDiff <= 60) {
    words = `${capitalize ? 'J' : 'j'}ust now`;
  } else if (hoursDiff < 1) {
    const minutes = Math.floor(secondsDiff / 60);
    words = `${minutes} ${pluralize(minutes, 'minute')} ago`;
  } else if (hoursDiff < 12) {
    words = `${hoursDiff} ${hoursDiff === 1 ? 'hour' : 'hours'} ago`;
  } else if (daysDiff <= 2) {
    const time = moment(date).format('h:mma');

    if (daysDiff === 0) {
      words = `${capitalize ? 'T' : 't'}oday at ${time}`;
    } else if (daysDiff === 1) {
      words = `${capitalize ? 'Y' : 'y'}esterday at ${time}`;
    } else {
      words = moment(date).format('MMMM D [at] h:mma');
    }
  } else if (yearsDiff < 0 || currentYear > updateYear) {
    words = moment(date).format('MMMM D, yyyy [at] h:mma');
  }

  // is date over a year? then add year to format
  const oldDate = new Date(dateInput);
  const todayDate = new Date();
  const thisYear = todayDate.getFullYear();
  const thatYear = oldDate.getFullYear();

  if (thisYear - thatYear > 1) {
    // 'dateInput is over one year'
    words = moment.utc(dateInput).local().format('MMMM D, YYYY [at] h:mma');
  }

  return words;
};

export const addDays = (numberOfdays: number): Date => {
  return moment.utc().add(numberOfdays, 'days').toDate();
};

export const isAfterUTCToday = (paramDate?: Date | null): boolean => {
  if (!paramDate) {
    return false;
  }
  return moment('00:00', 'HH:mm').utc().diff(paramDate) < 0;
};

export const getDateForFormattedDate = (
  paramDate: Date | null,
  format: dateFormat = dateFormat.MMMMDOYYYY
) => {
  if (!paramDate) {
    return null;
  }
  return moment(paramDate, format).toDate();
};

interface IGetDaysBetweenDatesConfig {
  includeStartDate?: boolean; // this makes the function include the start date in the days difference
  includeEndDate?: boolean; // this makes the function include the end date in the days difference
}
export const getDaysBetweenDates = (
  startDate: Date | MomentInput,
  endDate: Date | MomentInput,
  config: IGetDaysBetweenDatesConfig = {}
) => {
  const { includeStartDate = true, includeEndDate = false } = config;
  const start = moment(startDate);
  const end = moment(endDate);
  const diff = end.diff(start, 'days');
  const shouldInclude = includeStartDate || includeEndDate;
  const shouldIncludeBoth = includeStartDate && includeEndDate;
  return shouldIncludeBoth ? diff + 2 : shouldInclude ? diff + 1 : diff;
};

// eslint-disable-next-line import/no-unused-modules
export const getCurrentDate = () => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
};

export const checkIsTimeBeforeCurrentUTCTime = (
  time: string | number | Date,
  timeFormat = dateFormat.LTS
) => {
  const currentTime = moment.utc(moment().format(timeFormat), timeFormat);
  const timeToCheck = moment.utc(moment(time).format(timeFormat), timeFormat);
  return timeToCheck.isBefore(currentTime);
};

// map timezone stored in be to moment-timezone standard
export const TIME_ZONE_MAP = {
  'Pacific Time': 'America/Los_Angeles',
  'Arizona Time': 'America/Phoenix',
  'Mountain Time': 'America/Denver',
  'Central Time': 'America/Chicago',
  'Eastern Time': 'America/New_York',
  'Hawaii Time': 'Pacific/Honolulu',
  'Alaska Standard Time': 'America/Juneau',
};
