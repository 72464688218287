import { failTrackedDocuments, updateTrackedDocuments } from './helpers';
import {
  DocumentCopyActions,
  DocumentCreateActions,
  DocumentTypeKeys,
  IDocumentState,
  SingleDocumentDownloadActions,
} from './types';

export const initialState: IDocumentState = {
  createUi: {
    fileId: undefined,
    status: 'idle',
    trackDocumentCreate: {},
  },
  downloadUi: {},
  copyUi: {},
};

export default (
  state = initialState,
  action:
    | DocumentCreateActions
    | SingleDocumentDownloadActions
    | DocumentCopyActions
): IDocumentState => {
  switch (action.type) {
    case DocumentTypeKeys.CREATE_START:
      return {
        ...state,
        createUi: {
          fileId: action.fileId,
          status: 'loading',
          trackDocumentCreate: action.trackDocumentCreate,
        },
      };

    case DocumentTypeKeys.CREATE_SUCCESS:
      return {
        ...state,
        createUi: {
          ...state.createUi,
          status: 'success',
          trackDocumentCreate: updateTrackedDocuments(
            state.createUi.trackDocumentCreate,
            action.trackDocumentUpdate
          ),
        },
      };

    case DocumentTypeKeys.CREATE_ERROR:
      return {
        ...state,
        createUi: {
          ...state.createUi,
          status: 'error',
          trackDocumentCreate: failTrackedDocuments(
            state.createUi.trackDocumentCreate
          ),
        },
      };

    case DocumentTypeKeys.CREATE_RESET:
      return {
        ...state,
        createUi: {
          fileId: undefined,
          status: 'idle',
          trackDocumentCreate: {},
        },
      };

    case DocumentTypeKeys.SINGLE_DOWNLOAD_START:
      return {
        ...state,
        downloadUi: {
          ...state.downloadUi,
          [action.document.id]: { ...action.document, status: 'loading' },
        },
      };

    case DocumentTypeKeys.SINGLE_DOWNLOAD_SUCCESS:
      return {
        ...state,
        downloadUi: {
          ...state.downloadUi,
          [action.id]: {
            ...state.downloadUi[action.id],
            status: 'success',
          },
        },
      };

    case DocumentTypeKeys.SINGLE_DOWNLOAD_ERROR:
      return {
        ...state,
        downloadUi: {
          ...state.downloadUi,
          [action.id]: {
            ...state.downloadUi[action.id],
            status: 'error',
          },
        },
      };

    case DocumentTypeKeys.SINGLE_DOWNLOAD_CLEAR:
      const copy = {
        ...state.downloadUi,
      };
      delete copy[action.id];
      return {
        ...state,
        downloadUi: copy,
      };

    case DocumentTypeKeys.DOCUMENT_COPY_START:
      return {
        ...state,
        copyUi: {
          ...state.copyUi,
          [action.requestId]: {
            status: 'loading',
            fileId: action.input.fileId,
            sourceFileId: action.input.sourceFileId,
            documents: action.input.documents,
          },
        },
      };

    case DocumentTypeKeys.DOCUMENT_COPY_SUCCESS:
      return {
        ...state,
        copyUi: {
          ...state.copyUi,
          [action.requestId]: {
            ...state.copyUi[action.requestId],
            status: 'success',
            documents: [...state.copyUi[action.requestId].documents].map(
              (doc) => {
                const documentResult = action.copyResult.data?.find(
                  (result) => result.originalDocumentId === doc.sourceId
                );

                if (!!documentResult)
                  return {
                    ...doc,
                    status: documentResult.success ? 'success' : 'error',
                  };

                return {
                  ...doc,
                  status: 'error',
                };
              }
            ),
          },
        },
      };

    case DocumentTypeKeys.DOCUMENT_COPY_ERROR:
      return {
        ...state,
        copyUi: {
          ...state.copyUi,
          [action.requestId]: {
            ...state.copyUi[action.requestId],
            status: 'error',
            errorMessages: action.errorMessages,
            documents: [...state.copyUi[action.requestId].documents].map(
              (doc) => {
                return {
                  ...doc,
                  status: 'error',
                };
              }
            ),
          },
        },
      };

    case DocumentTypeKeys.DOCUMENT_COPY_CLEAR:
      const duplicateCopyUi = { ...state.copyUi };
      return {
        ...state,
        copyUi: duplicateCopyUi,
      };

    default:
      return state;
  }
};
