import React, { useEffect, useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import {
  CollapsibleHeader,
  ChevronIcon,
  CollapsibleDivider,
  CollapsibleContent,
} from './Common';
import { collapsibleShadow } from 'config/theme';
import styled from 'styled-components';
import { gray1, white } from 'config/colors';
import SmallArrowRight from 'icons/SmallArrowRight';
import { IMMUTABLE_HTML_ATTRIBUTE } from 'utils/constants';
import { TransitionProps } from '@material-ui/core/transitions/transition';

export interface ICollapsibleCard {
  className?: string;
  /* Component that will be used as a title  */
  title?: React.ReactNode | React.ReactNodeArray | string;
  /* Displays a line between the collapsible header and the collapsible content  */
  divider?: boolean;
  /* If `true`, the accordion will be displayed in a disabled state. */
  disabled?: boolean;
  /* Controls if the component should be open as default or not. Default is set to true */
  expanded?: boolean;
  /* Undefined */
  expandIcon?: React.ReactNode | undefined | null;
  /* Component that will the content of the collapsible  */
  children: React.ReactNode | React.ReactNodeArray | string;
  darker?: boolean;
  id?: string;
  disablehover?: string;
  headerPaddingLeft?: string;
  headerPadding?: string;
  disableExpansion?: boolean;
  onExpandToggle?: (expanded: boolean) => void;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  seoClass?: string;
  headerNode?: React.ReactNode | React.ReactNodeArray;
  headerStyles?: React.CSSProperties;
  style?: React.CSSProperties;
  renderSmallArrowIcon?: boolean;
  pendoId?: string;
  pendoClass?: string;
  stickyContent?: React.ReactNode | React.ReactNodeArray | string | null;
  dataTestId?: string;
  TransitionProps?: TransitionProps;
}

const CollapsibleCard: React.FC<ICollapsibleCard> = ({
  children,
  className,
  darker = false,
  dataTestId,
  disabled = false,
  disablehover = 'false',
  headerPaddingLeft,
  headerPadding,
  disableExpansion = false,
  divider,
  expanded = true,
  expandIcon,
  headerNode,
  headerStyles,
  id,
  onExpandToggle,
  onMouseOver,
  onMouseLeave,
  pendoClass,
  pendoId,
  renderSmallArrowIcon,
  seoClass,
  stickyContent,
  style,
  title,
  TransitionProps,
}) => {
  const [isExpanded, setIsExpanded] = useState(expanded);

  useEffect(() => {
    setIsExpanded(expanded && !disabled);
  }, [expanded, disabled]);

  const StyledAccordion = darker ? DarkStyledAccordion : NormalStyledAccordion;
  const containerPendoId = pendoId ? `${pendoId}--CardContainer` : undefined;
  const accordionStyle = (style || {
    borderRadius: '3px',
  }) as React.CSSProperties;
  const headerStyle = (headerStyles || {
    minHeight: '60px',
  }) as React.CSSProperties;

  const arrowIcon = renderSmallArrowIcon ? (
    <span
      className={
        pendoClass
          ? `${pendoClass}__main-small-carrot`
          : `${seoClass}_main-carrot`
      }
      id={pendoId && `${pendoId}__main-small-carrot`}
    >
      <SmallArrowRight style={{ transform: 'rotate(45deg)' }} />
    </span>
  ) : (
    <span
      className={
        pendoClass ? `${pendoClass}__main-carrot` : `${seoClass}-main-carrot`
      }
      id={pendoId && `${pendoId}__main-carrot`}
    >
      <ChevronIcon />
    </span>
  );

  const defaultIcon = expandIcon === null ? null : arrowIcon;

  return (
    <div
      className={className}
      {...{
        [IMMUTABLE_HTML_ATTRIBUTE]: containerPendoId,
      }}
      id={id}
    >
      <StyledAccordion
        TransitionProps={TransitionProps}
        expanded={isExpanded}
        disabled={disabled}
        onChange={() => {
          if (!disableExpansion) {
            setIsExpanded(!isExpanded);
            onExpandToggle?.(!isExpanded);
          }
        }}
        style={accordionStyle}
        data-testid={dataTestId}
      >
        <div>
          <CollapsibleHeader
            style={headerStyle}
            disablehover={disablehover}
            $headerPaddingLeft={headerPaddingLeft}
            $headerPadding={headerPadding}
            className={seoClass}
            expandIcon={expandIcon ?? defaultIcon}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            {...{
              [IMMUTABLE_HTML_ATTRIBUTE]: pendoId,
            }}
          >
            {title && (
              <div
                className={`accordion__title ${seoClass}_Project`}
                style={{ width: headerNode ? 'fit-content' : '100%' }}
              >
                {title}
              </div>
            )}
            {headerNode && headerNode}
          </CollapsibleHeader>
          {stickyContent && stickyContent}
        </div>
        {divider && isExpanded && <CollapsibleDivider />}
        {children && (
          <CollapsibleContent
            data-testid="content"
            className={`${seoClass}_order`}
          >
            {children}
          </CollapsibleContent>
        )}
      </StyledAccordion>
    </div>
  );
};

export default CollapsibleCard;

const NormalStyledAccordion = styled(Accordion)`
  box-shadow: ${collapsibleShadow};
  && {
    background-color: ${white};
  }
`;

const DarkStyledAccordion = styled(Accordion)`
  box-shadow: none;
  && {
    background-color: ${gray1};
  }
`;
