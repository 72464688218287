import { AxiosError } from 'axios';
import { push } from 'connected-react-router';
import { AsyncAction, Dispatch } from 'store';
import {
  AuthLoadingKeys,
  AuthTypeKeys,
  ICheckMfaStatus,
  IGetSession,
  ISession,
  MfaStatusConflictType,
  UserType,
} from 'store/auth/types';
import Poller from 'services/poller';
import workspaceAPI from 'services/workspaceAPI';
import { setLoadingStateAction } from 'store/ui/actions';
import { broadcastSessionUpdate, broadcastLogOut } from './channels';
import { isInternalUser } from './getters';
import { setAuthenticatedUserContext } from 'utils/appInsights';
import { extractQueryParams } from 'utils/url';
import { setAfterLoginRedirectPath } from 'utils/auth';

export function updateSession(
  session: Partial<ISession> | null,
  refreshed = false
) {
  return {
    type: AuthTypeKeys.UPDATE_SESSION,
    session,
    refreshed,
  };
}

export function redirectToSignIn() {
  window.location.href = `${AppConfig.workspaceBackendUrl}/auth/signin`;
}

export function redirectToSignInExternal() {
  window.location.href = `${AppConfig.workspaceBackendUrl}/external/signin`;
}

let appInsightsUserSet = false;

function setAppInsightsUser(email) {
  if (appInsightsUserSet) {
    return;
  }
  appInsightsUserSet = true;
  setAuthenticatedUserContext(email, undefined, true);
}

export const manuallyGetSession = (refresh = true, broadcastUpdate = true) => (
  dispatch: Dispatch,
  getState
) => {
  dispatch(
    setLoadingStateAction(AuthLoadingKeys.authSessionTimeoutLoading, true)
  );
  return workspaceAPI
    .get('/users/_me', {
      params: {
        refresh,
      },
    })
    .then((response) => {
      onCompleteGetSession(
        // No poller
        undefined,
        response,
        dispatch,
        isInternalUser(getState().auth)
      );

      return response.data;
    })
    .then((session) => {
      // Broadcast our new session data to other tabs
      if (broadcastUpdate) {
        broadcastSessionUpdate(session);
      }
    })
    .catch(({ response }) => {
      const { auth } = getState();
      onGetSessionError(undefined, response, dispatch, auth);
    })
    .finally(() => {
      dispatch(
        setLoadingStateAction(AuthLoadingKeys.authSessionTimeoutLoading, false)
      );
    });
};

export const onCompleteGetSession = (
  poller,
  response,
  dispatch,
  isInternal,
  props?
) => {
  const { data, status, config } = response;
  const refreshed = config?.params?.refresh;
  if (status === 417) {
    dispatch(push(`/error/security?external=${!isInternal}`));
    poller?.stop?.();
    return;
  }

  if (data.authenticated) {
    dispatch(
      updateSession(
        {
          ...data,
          emailVerificationExpired: false,
        },
        refreshed
      )
    );
    setAppInsightsUser(data.email);
    setWhatfixIdentifiers(data);
  } else if (props.type === UserType.Internal) {
    // NOTE: detect internal and redirect to signin (internal)
    redirectToSignIn();
  } else {
    // NOTE: right now just stop polling if not authenticated
    // and is not internal user
    dispatch(updateSession(data, refreshed));
    poller?.stop?.();
  }
};

export const onGetSessionError = (poller, response, dispatch, auth) => {
  if (response?.status === 403) {
    const {
      data: { email, reason },
    } = response || {};
    if (reason === 'email_verification') {
      dispatch(
        updateSession({
          email,
          emailVerificationExpired: true,
        })
      );
      return;
    }
    if (reason === 'deactivated_account') {
      setAfterLoginRedirectPath('/error/403');
    }
  }
  const signOutPath = isInternalUser(auth)
    ? '/employee/signout'
    : '/customer/signout';

  poller?.stop?.();
  broadcastLogOut(signOutPath);
  dispatch(push(signOutPath));
  clearWhatfixIdentifiers();
  return;
};

export function getSession(props: IGetSession) {
  return (dispatch: Dispatch, getState) => {
    const maxSessionRetries =
      Number(AppConfig.maxSessionRetries) > 0
        ? Number(AppConfig.maxSessionRetries)
        : 0;
    const poller = new Poller(
      () => {
        const {
          values: { emailCode },
          restSearchParams,
        } = extractQueryParams(location.search, ['emailCode']);
        if (emailCode) {
          const newURL = `${location.pathname}${restSearchParams}`;
          history.replaceState(null, '', newURL);
        }

        return workspaceAPI.get(`/users/_me`, {
          params: {
            emailCode,
            refresh: getState().auth.refresh,
          },
        });
      },
      {
        interval: 30000,
        onComplete: (response) => {
          const { auth } = getState();
          const isInternal = isInternalUser(auth);
          onCompleteGetSession(this, response, dispatch, isInternal, props);
        },
        onError: ({ response }) => {
          const { auth } = getState();
          onGetSessionError(this, response, dispatch, auth);
        },
        maxRetryCount: maxSessionRetries,
      }
    );
    poller.start();
  };
}

export function setMfaStatus(
  status: number | null,
  conflictType: MfaStatusConflictType | null
) {
  return {
    type: AuthTypeKeys.SET_MFA_STATUS,
    status,
    conflictType,
  };
}

export function checkMfaStatus(params: ICheckMfaStatus): AsyncAction<void> {
  return (dispatch) => {
    dispatch({ type: AuthTypeKeys.CHECK_MFA_STATUS });

    return workspaceAPI
      .head(params.url)
      .then(({ status }) => {
        if (status === 200) {
          dispatch(setMfaStatus(status, null));
        }
      })
      .catch(({ response }: AxiosError) => {
        if (!response) return;
        const conflictType = response.headers['conflict-type'];
        dispatch(
          setMfaStatus(response.status, MfaStatusConflictType[conflictType])
        );
      });
  };
}

export function resetMfaState() {
  return (dispatch) => {
    dispatch({ type: AuthTypeKeys.RESET_MFA_STATE });
  };
}

function setWhatfixIdentifiers(data) {
  if (data) {
    localStorage.setItem('region', 'NCS');
    localStorage.setItem('office', data.officeIds);
    localStorage.setItem('clarityFirstStartDate', data.startDate);
    localStorage.setItem('features', JSON.stringify(data.features));
  }
}

export function clearWhatfixIdentifiers() {
  localStorage.removeItem('region');
  localStorage.removeItem('office');
  localStorage.removeItem('clarityFirstStartDate');
  localStorage.removeItem('features');
}
