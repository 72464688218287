export { _RoleTypes as RoleTypes } from './roleTypes';
import { IPartyFormContactValues } from 'components/OrderAddOrganizationForm/AddParty/AddParty.types';
import { OrderPartiesRole, OrderPartyRoleId } from 'store/orders/types';
import { Seller1099Name } from 'utils/constants';

interface IContactInfo {
  email?: string;
  phone?: string;
  cellular?: string;
  homePhone?: string;
  homeFax?: string;
  businessPhone?: string;
  businessFax?: string;
}

export interface IContact {
  name: string;
  id: number;
  address: string | null;
  contactInfo: IContactInfo;
}

export interface IGabContact {
  name: string;
  firstName: string;
  lastName: string;
  value: string;
  id: number;
  address?: string;
  contactInfo?: IContactInfo;
}

interface IOrganizationData {
  organizationId: number;
  fastId: number;
  principalType: IPrincipalType;
  organizationName: string;
  stateofIncorporation?: string;
  SSN?: string;
  organizationPhone?: string;
  individual?: IIndividual;
  trustEstate?: ITrustEstate;
  organizationAddress?: IOrganizationAddress;
  businessEntity?: IBusinessEntity;
  couple?: ICouple;
  organizationContacts?: IOrganizationContactForm[];
}

export interface INewOrganizationFromGAB {
  organizationId: number;
  roleType: IRoleType;
  referenceNumber?: string;
}

export interface IPartyRequest {
  roleType: IRoleType;
  organizationAddress?: IOrganizationAddress;
  organizationPhone?: string;
  businessOrganization?: IBusinessOrganization;
  organizationContacts?: IPartyContact[];
  id?: string;
  hasInvoice?: boolean;
  hasDisbursement?: boolean;
}

interface ICouple {
  spouse1: ISpouse;
  spouse2: ISpouse;
}

interface ISpouse {
  firstName: string;
  lastName: string;
  ssn?: string;
  phone?: string;
  phoneType?: number;
  email?: string;
  taxForm1099SType?: number;
}

export interface IContactIdAndState {
  id: string;
  value: boolean;
}

export interface IOrderOrganizationState {
  allOrganizations: IGABOrganizationsHash;
  organizationContacts: IOrganizationContactsHash;
  entityTypes: ISetEntityTypesHash;
  gabOrganizationContacts: IContact[];
  organizationData: IOrganizationData;
  gabOrganizationAddress: IOrganizationAddress;
  partiesData: IOrderAllBusinessParties;
  partiesSalesforce: IOrderAllBusinessParties;
}

export interface IGABOrganizationsHash {
  [key: string]: IGABOrganizationForm;
}

export interface IGABOrganizationForm {
  organizationId?: number;
  roleType: string;
  principalType?: number;
  organizationName?: string;
  organizationPhone?: string;
  organizationEmail?: string;
  addressType?: string | null;
  street?: string;
  city: string;
  state?: string;
  zip?: string;
  country?: string;
  //entityTypeId?: number; //obsolete, to be deleted
  organizationContacts?: IOrganizationContactForm[];
  isGABRequest: boolean;
  sendInviteToContactsEnabled?: boolean;
  fastId?: number;
  organizationPhoneType: number;
  entityType?: IEntityType;
}

interface IAddress {
  addressType?: number;
  streetLine4?: string | null;
  streetLine3?: string | null;
  streetLine2?: string | null;
  streetLine1?: string | null;
  state?: string;
  zip?: string;
  country?: string;
  city: string;
  county?: string;
  id?: number;
}

export interface IPrincipalForm {
  adHocFlag?: boolean;
  businessEntity?: IBusinessEntity;
  couple?: ICouple;
  fastId?: string | undefined;
  fileBusinessPartyId?: number;
  individual?: IIndividual;
  isGABRequest?: boolean;
  organizationAddress?: IAddress;
  cfOrganizationContacts?: IPartyFormContactValues[];
  organizationPhone?: string;
  organizationPhoneType?: number;
  principalRole?: IRoleType; //Buyer or Seller
  principalType: IPrincipalType;
  roleType: IRoleType;
  sendInviteToContactsEnabled?: boolean;
  SSN?: string;
  trustEstate?: ITrustEstate;
}

export interface INonPrincipalForm {
  organizationId?: number;
  RealEstateBrokerType?: number | undefined;
  BrokerOrganizationId?: number | undefined;
  RealEstateAgentType?: number;
  AgentOrganizationId?: number;
  organizationName?: string;
  stateofIncorporation?: string;
  organizationPhone?: string;
  organizationContacts?: IPrincipalContactForm[];
  organizationAddress?: IAddress;
  sendInviteToContactsEnabled?: boolean;
  entityType?: number;
  organizationPhoneType?: number;
  isGABRequest?: boolean;
  fileId?: string | undefined;
  referenceNumber?: string;
}

export interface ILenderForm {
  roleType?: number;
  organizationName?: string;
  stateofIncorporation?: string;
  organizationPhone?: string;
  organizationContacts?: IPrincipalContactForm[];
  organizationAddress?: IAddress;
  sendInviteToContactsEnabled?: boolean;
  entityType?: number;
  organizationPhoneType?: number;
  isGABRequest?: boolean;
  fileId?: string | undefined;
  loanNumber?: string;
  loanType?: number;
  loanId?: number;
}

interface IGABOrganizations {
  type: OrderOrganizationTypeKeys.SET_ALL_ORGANIZATIONS;
  organizations: IGABOrganizationsHash;
}

export interface IOrganizationContactForm {
  contactFirstName?: string;
  contactLastName?: string;
  contactEmail?: string;
  contactPhone?: IContactPhone;
}

interface IPrincipalContactForm {
  contactFirstName?: string;
  contactLastName?: string;
  contactEmail?: string;
  contactPhone?: IPrincipalContactPhone;
}

interface IPrincipalContactPhone {
  phoneType?: number;
  areaCode?: string;
  number?: string;
  countryCode?: string;
  extension?: string;
  comments?: string;
  phoneNumberAddressBookId?: string;
}

interface IContactPhone {
  PhoneType?: number;
  AreaCode?: string;
  Number?: string;
}

export enum OrderOrganizationLoadingKeys {
  EntityTypesLoading = 'orderOrganization/EntityTypesLoading',
  OrganizationDataByPartyIdLoading = 'orderOrganization/OrganizationDataByPartyIdLoading',
}

export enum OrderOrganizationTypeKeys {
  SET_ENTITY_TYPES = 'orderOrganization/SET_ENTITY_TYPES',
  SET_ALL_ORGANIZATIONS = 'orderOrganization/SET_ALL_ORGANIZATIONS',
  SET_ORGANIZATION_CONTACTS = 'orderOrganization/SET_ORGANIZATION_CONTACTS',
  SET_ALL_BUSINESS_PARTIES = 'orderOrganzation/SET_ALL_BUSINESS_PARTIES',
  SET_ALL_PARTIES_SALESFORCE = 'orderOrganzation/SET_ALL_PARTIES_SALESFORCE',
  REQUEST = 'orderOrganization/REQUEST',
  SUCCESS = 'orderOrganization/SUCCESS',
  ERROR = 'orderOrganization/ERROR',
  SET_ORGANIZATION_DATA = 'orderOrganization/SET_ORGANIZATION_DATA',
  RESET_ORGANIZATION_DATA = 'orderOrganization/RESET_ORGANIZATION_DATA',
  ADD_PRINCIPAL_REQUEST = 'orderOrganization/ADD_PRINCIPAL_REQUEST',
  ADD_PRINCIPAL_REQUEST_ERROR = 'orderOrganization/ADD_PRINCIPAL_REQUEST_ERROR',
  ADD_PRINCIPAL_REQUEST_SUCCESS = 'orderOrganization/ADD_PRINCIPAL_REQUEST_SUCCESS',
  ADD_BROKER_AGENT_REQUEST = 'orderOrganization/ADD_BROKER_AGENTL_REQUEST',
  ADD_BROKER_AGENT_REQUEST_ERROR = 'orderOrganization/ADD_BROKER_AGENT_REQUEST_ERROR',
  ADD_BROKER_AGENT_REQUEST_SUCCESS = 'orderOrganization/ADD_BROKER_AGENT_REQUEST_SUCCESS',
  ADD_BROKER_REQUEST = 'orderOrganization/ADD_BROKER_REQUEST',
  ADD_BROKER_REQUEST_ERROR = 'orderOrganization/ADD_BROKER_REQUEST_ERROR',
  ADD_BROKER_REQUEST_SUCCESS = 'orderOrganization/ADD_BROKER_REQUEST_SUCCESS',
  ADD_ATTORNEYS_REQUEST = 'orderOrganization/ADD_ATTORNEYS_REQUEST',
  ADD_ATTORNEYS_REQUEST_ERROR = 'orderOrganization/ADD_ATTORNEYS_REQUEST_ERROR',
  ADD_ATTORNEYS_REQUEST_SUCCESS = 'orderOrganization/ADD_ATTORNEYS_REQUEST_SUCCESS',
  ADD_EXCHANGE_COMPANY_REQUEST = 'orderOrganization/ADD_EXCHANGE_COMPANY_REQUEST',
  ADD_EXCHANGE_COMPANY_REQUEST_ERROR = 'orderOrganization/ADD_EXCHANGE_COMPANY_REQUEST_ERROR',
  ADD_EXCHANGE_COMPANY_REQUEST_SUCCESS = 'orderOrganization/ADD_EXCHANGE_COMPANY_REQUEST_SUCCESS',
  ADD_BUSINESS_ENTITY_TYPE = 'orderOrganization/ADD_BUSINESS_ENTITY_TYPE',
  ADD_LENDER_REQUEST = 'orderOrganization/ADD_LENDER_REQUEST',
  ADD_LENDER_REQUEST_ERROR = 'orderOrganization/ADD_LENDER_REQUEST_ERROR',
  ADD_LENDER_REQUEST_SUCCESS = 'orderOrganization/ADD_LENDER_REQUEST_SUCCESS',
  ADD_NEW_LOAN_PARTY_REQUEST = 'orderOrganization/ADD_NEW_LOAN_PARTY_REQUEST',
  ADD_NEW_LOAN_PARTY_ERROR = 'orderOrganization/ADD_NEW_LOAN_PARTY_ERROR',
  ADD_NEW_LOAN_PARTY_SUCCESS = 'orderOrganization/ADD_NEW_LOAN_PARTY_SUCCESS',
  ADD_TRUSTEE_REQUEST = 'orderOrganization/ADD_TRUSTEE_REQUEST',
  ADD_TRUSTEE_REQUEST_ERROR = 'orderOrganization/ADD_TRUSTEE_REQUEST_ERROR',
  ADD_TRUSTEE_REQUEST_SUCCESS = 'orderOrganization/ADD_TRUSTEE_REQUEST_SUCCESS',
  SUCCESSFULLY_ADDED_BUSINESS_ENTITY_TYPE = 'orderOrganization/SUCCESSFULLY_ADDED_BUSINESS_ENTITY_TYPE',
  ERROR_ADDED_BUSINESS_ENTITY_TYPE = 'orderOrganization/ERROR_ADDED_BUSINESS_ENTITY_TYPE',
  ADD_AGENT_REQUEST = 'orderOrganization/ADD_AGENT_REQUEST',
  ADD_AGENT_REQUEST_ERROR = 'orderOrganization/ADD_AGENT_REQUEST_ERROR',
  ADD_AGENT_REQUEST_SUCCESS = 'orderOrganization/ADD_AGENT_REQUEST_SUCCESS',
  UPDATE_PRINCIPAL_REQUEST = 'orderOrganization/UPDATE_PRINCIPAL_REQUEST',
  UPDATE_PRINCIPAL_REQUEST_ERROR = 'orderOrganization/UPDATE_PRINCIPAL_REQUEST_ERROR',
  UPDATE_PRINCIPAL_REQUEST_SUCCESS = 'orderOrganization/UPDATE_PRINCIPAL_REQUEST_SUCCESS',
  UPDATE_ATTORNEYS_REQUEST = 'orderOrganization/UPDATE_ATTORNEYS_REQUEST',
  UPDATE_BROKER_REQUEST = 'orderOrganization/UPDATE_BROKER_REQUEST',
  UPDATE_EXCHANGE_COMPANY_REQUEST = 'orderOrganization/UPDATE_EXCHANGE_COMPANY_REQUEST',
  UPDATE_EXCHANGE_COMPANY_ERROR = 'orderOrganization/UPDATE_EXCHANGE_COMPANY_ERROR',
  UPDATE_AGENTS_REQUEST = 'orderOrganization/UPDATE_REAL_ESTATE_AGENT_REQUEST',
}

export interface IOrganizationContactsHash {
  [key: string]: IOrganizationContactForm;
}

interface IResetOrganizationData {
  type: OrderOrganizationTypeKeys.RESET_ORGANIZATION_DATA;
}

interface ISetOrganizationContacts {
  type: OrderOrganizationTypeKeys.SET_ORGANIZATION_CONTACTS;
  contacts: IOrganizationContactsHash;
}

interface ISetOrganizationData {
  type: OrderOrganizationTypeKeys.SET_ORGANIZATION_DATA;
  organizationData: IOrganizationData;
}

interface ISetOrderBusinessParties {
  type: OrderOrganizationTypeKeys.SET_ALL_BUSINESS_PARTIES;
  partiesData: IOrderAllBusinessParties;
}

interface ISetOrderBusinessPartiesSalesforce {
  type: OrderOrganizationTypeKeys.SET_ALL_PARTIES_SALESFORCE;
  partiesSalesforce: IOrderAllBusinessParties;
}

export type OrderOrganizationAction =
  | ISetEntityTypes
  | IGABOrganizations
  | ISetOrganizationContacts
  | IGetGabOrganizationContacts
  | ISetGabOrganizationContacts
  | IResetGabOrganizationContacts
  | ISetOrganizationData
  | IResetOrganizationData
  | ISetGabOrganizationAddress
  | IResetGabOrganizationAddress
  | ISetOrderBusinessParties
  | ISetOrderBusinessPartiesSalesforce;

export enum OrganizationLoadingKeys {
  organizationsLoading = 'organizationsLoading',
  organizationContactsLoading = 'organizationContactsLoading',
  organizationAddContactLoading = 'orderAddContactLoading',
  addOrganizationLoading = 'addOrganizationLoading',
  updateOrganizationLoading = 'updateOrganizationLoading',
  orderEOPOrganizationContactsLoading = 'orderEOP/OrganizationContactsLoading',
  organizationDataByPartyIdLoading = 'organizationDataByPartyIdLoading',
  organizationAddressLoading = 'orderOrganization/GAB_ADDRESS_LOADING',
}

export enum RoleDescription {
  RealEstateBroker = 'Real Estate Broker',
  Attorney = 'Attorney',
  RealEstateAgent = 'Real Estate Agent',
  Broker = 'Broker',
  Borrower = 'Borrower',
  ExchangeCompany = 'Exchange Company',
}

export enum RoleType {
  AssumptionLender = 673,
  Buyer = 113,
  Seller = 114,
  BuyersAttorney = 322,
  BuyerBroker = 323,
  SellersAttorney = 325,
  SellersBroker = 326,
  OtherBroker = 675,
  BuyersAgent = 685,
  SellersAgent = 686,
  ExchangeCompany = 1501,
  NewLender = 688,
  PayOffLender = 674,
  Payee = 679,
  Trustee = 92,
  AttorneyCoCounsel = 703,
  AttorneyLocal = 704,
  AttorneyPrimary = 702,
  LoanOfficer = 3220,
  MortgageBroker = 670,
  LoanProcessor = 3221,
}

export enum EclipsePartyRoleTypes {
  RealEstateBroker = 88,
  RealEstateAgent = 89,
  ExchangeCompany = 1307,
  Attorney = 86,
}

export enum OrderEOPActionTypes {
  GET_GAB_ORGANIZATION_CONTACTS = 'orderEOPActionTypes/GET_GAB_ORGANIZATION_CONTACTS',
  SET_GAB_ORGANIZATION_CONTACTS = 'orderEOPActionTypes/SET_GAB_ORGANIZATION_CONTACTS',
  RESET_GAB_ORGANIZATION_CONTACTS = 'orderEOPActionTypes/RESET_GAB_ORGANIZATION_CONTACTS',
  SET_GAB_ORGANIZATION_ADDRESS = 'orderEOPActionTypes/SET_GAB_ORGANIZATION_ADDRESS',
  RESET_GAB_ORGANIZATION_ADDRESS = 'orderEOPActionTypes/RESET_GAB_ORGANIZATION_ADDRESS',
  GET_GAB_ORGANIZATION_ADDRESS_ERROR = 'orderEOPActionTypes/GET_GAB_ORGANIZATION_ADDRESS_ERROR',
}

interface IGetGabOrganizationContacts {
  type: OrderEOPActionTypes.GET_GAB_ORGANIZATION_CONTACTS;
  contacts: IContact[];
}

interface ISetGabOrganizationContacts {
  type: OrderEOPActionTypes.SET_GAB_ORGANIZATION_CONTACTS;
  contacts: IContact[];
}

interface IResetGabOrganizationContacts {
  type: OrderEOPActionTypes.RESET_GAB_ORGANIZATION_CONTACTS;
}

interface ISetGabOrganizationAddress {
  type: OrderEOPActionTypes.SET_GAB_ORGANIZATION_ADDRESS;
  address: IOrganizationAddress;
}

interface IResetGabOrganizationAddress {
  type: OrderEOPActionTypes.RESET_GAB_ORGANIZATION_ADDRESS;
}

export interface IGabOrganization {
  organizationId: number;
  businessOrganizationId: number;
  organizationName: string;
  gabCode: string;
  organizationEmail: string;
  organizationPhone: string;
  entityType: string;
  address: string;
  city: string;
  state: string;
}

interface IGABBusinessOrganization {
  businessOrganizationId?: number;
  addressBookId?: number;
  code?: string;
  name: string;
  name2?: string;
  gabEntityType?: IRoleType;
  principalType?: IPrincipalType;
  adHocFlag?: boolean;
}

interface IBusinessOrganization {
  id?: number;
  addressBookId?: number;
  referenceNumber?: string;
  code?: string;
  name?: string;
  name2?: string;
  type?: IRoleType;
  adHocFlag?: boolean;
  adHocWithoutGERQ?: boolean;
}

export interface IEntityType {
  id: number;
  description: string;
}

export interface ISetEntityTypesHash {
  [key: string]: IEntityType;
}

export interface ISetEntityTypes {
  type: OrderOrganizationTypeKeys.SET_ENTITY_TYPES;
  entityTypes: ISetEntityTypesHash;
}

interface IPrincipalDetail {
  availableGABContacts: IPartyContact[];
  businessPartyId: number;
  businessEntity?: IBusinessEntity;
  couple?: ICouple;
  fastId: number;
  gabBusinessOrganization?: IGABBusinessOrganization; // this is added for non-principal parties
  individual?: IIndividual;
  organizationAddress?: IOrganizationAddress;
  organizationContacts?: IPartyContact[];
  organizationId: number;
  organizationName: string;
  organizationPhone?: string;
  principalId?: number;
  principalType: IPrincipalType;
  sendInviteToContactsEnabled?: boolean;
  trustEstate?: ITrustEstate;
}

interface IType {
  id: OrderPartyRoleId;
  description: OrderPartiesRole;
}

export enum GabStatus {
  SUBMITTED = 1,
  ADDED = 2,
  MODIFIED = 3,
  DUPLICATE = 4,
  REJECTED = 5,
  ON_HOLD = 6,
}

export enum GrqType {
  NEW = 1572,
  MODIFIED = 1573,
}

export interface IParty {
  attorneyType: IPrincipalType;
  availableGABContacts: IPartyContact[];
  businessEntityStateOfIncorporation?: string;
  businessPartyId: number;
  businessEntityTypeId?: number;
  childPartyIds: string[];
  createdTime: string;
  ein: string;
  email?: string;
  fastPartyId: string;
  firstName?: string;
  gabBusinessOrganization: {
    addressBookId: number;
    adHocFlag: boolean;
    businessOrganizationId: number;
    code: string;
    gabEntityType: IType;
    name: string;
    name2: string;
    principalDescription: string;
    principalType: string;
  };
  gabComment: string;
  gabProcessingFlag: boolean;
  gabStatusType: { id: GabStatus; description: string };
  gerqRequestType: { id: GrqType; description: string };
  hasSpouse1SSN?: boolean;
  hasSpouse2SSN?: boolean;
  hasSSN?: boolean;
  hasTIN?: boolean;
  id: string;
  lastModifiedTime: string;
  lastName?: string;
  loanNumber?: string;
  middleName?: string;
  organizationAddress: IOrganizationAddress;
  organizationContacts: IPartyContact[];
  organizationPhone: string;
  parentPartyId: string;
  principalType?: OrderPartyRoleId;
  referenceNumber: string;
  roleType: IType;
  rulesEngineFlags: {
    cancelledFinalizedInvoice: boolean;
    finalAdjustedInvoice: boolean;
    finalInvoice: boolean;
    issuedDisbursement: boolean;
  };
  salesforcePartyId: number;
  salesforcePartyReferenceId: number;
  seller1099SFormType: Seller1099Name;
  sequenceNumber: number;
  spouse1Email?: string;
  spouse1FirstName?: string;
  spouse1LastName?: string;
  spouse1MiddleName?: string;
  spouse1Phone?: string;
  spouse1Seller1099SFormType: Seller1099Name;
  spouse1SSN?: string;
  spouse2Email?: string;
  spouse2FirstName?: string;
  spouse2LastName?: string;
  spouse2MiddleName?: string;
  spouse2Phone?: string;
  spouse2Seller1099SFormType: Seller1099Name;
  spouse2SSN?: string;
  ssn: string;
  taxIdAPN?: string;
  trustDate: string;
}

export interface IOrderAllBusinessParties {
  businessSource?: IBusinessSource[];
  buyers?: IPrincipalDetail[];
  exchangeCompanies?: IExchangeCompany[];
  lenders?: ILender[];
  newLoanLenders?: ILender[];
  party: IParty[];
  realEstateAgents?: IRealEstateAgent[];
  realEstateBrokers?: IRealEstateBroker[];
  sellers?: IPrincipalDetail[];
}

// eslint-disable-next-line import/no-unused-modules
export interface IExchangeCompany {
  availableGABContacts: IPartyContact[];
  businessPartyId?: number;
  gabBusinessOrganization?: IGABBusinessOrganization;
  organizationAddress?: IOrganizationAddress;
  organizationContacts?: IPartyContact[];
  organizationPhone?: string;
  parentOrganization?: IParentOrganization;
  referenceNumber?: string;
  roleType: IRoleType;
}

interface ILender {
  id?: number;
  businessPartyId?: number;
  gabBusinessOrganization: IGABBusinessOrganization;
  organizationAddress?: IOrganizationAddress;
  organizationContacts?: IPartyContact[];
  organizationPhone?: string;
  parentOrganization?: IParentOrganization;
  referenceNumber?: string;
  sequenceNumber?: number;
  roleType: IRoleType;
}

interface IRealEstateAgent {
  availableGABContacts: IPartyContact[];
  businessPartyId: number;
  gabBusinessOrganization: IGABBusinessOrganization;
  id?: number;
  organizationAddress?: IOrganizationAddress;
  organizationContacts?: IPartyContact[];
  organizationPhone: string;
  parentOrganization?: IParentOrganization;
  realEstateBroker: IRealEstateBroker;
  referenceNumber?: string;
  roleType: IRoleType;
}

interface IRealEstateBroker {
  availableGABContacts: IPartyContact[];
  businessPartyId: number;
  gabBusinessOrganization: IGABBusinessOrganization;
  id?: number;
  organizationAddress?: IOrganizationAddress;
  organizationContacts?: IPartyContact[];
  organizationPhone: string;
  referenceNumber?: string;
  roleType: IRoleType;
  existingAgentId?: number;
}

interface IBusinessSource {
  businessPartyId: number;
  roleType: IRoleType;
  organizationAddress?: IOrganizationAddress;
  gabBusinessOrganization: IGABBusinessOrganization;
  organizationPhone: string;
}

export interface IPartyContact {
  adhocFlag?: boolean;
  email: string;
  fileBusinessPartyid?: number;
  firstName: string;
  fullName?: string;
  gabContactId?: number;
  id?: number;
  isDeleted?: boolean;
  isInFast?: boolean;
  lastName: string;
  phone?: string;
  primaryContact?: boolean;
  primaryFlag?: boolean;
  salesforceId?: string;
  status?: string;
  updateInFast?: boolean;
  updateInGab?: boolean;
}

interface IPrincipalType {
  id: number;
  description?: string;
}

// Mapped to: Workspace.API.Forms.RoleType
export interface IRoleType {
  id: number;
  description?: string;
  objectCd?: string;
}

interface ITrustEstate {
  organizationId?: number;
  shortName: string;
  organizationType?: number;
  ssn?: string;
  trustDate?: Date;
  tin?: string;
  taxForm1099SType?: number;
  gabBusinessOrganization?: IGABBusinessOrganization;
}

interface IIndividual {
  email?: string;
  firstName: string;
  lastName: string;
  phone?: string;
  phoneType?: number;
  ssn?: string;
  taxForm1099SType?: number;
}

interface IBusinessEntityType {
  id: number;
  description: string;
  objectCd?: string;
}

interface IStateOfIncorporation {
  id: number;
  name: string;
}

interface IParentOrganization {
  id: number;
  roleType: IRoleType;
  businessPartyId: number;
}

interface IBusinessEntity {
  organizationId?: number;
  shortName?: string;
  organizationType?: number;
  stateofIncorporation?: IStateOfIncorporation;
  tin?: string;
  businessEntityType?: IBusinessEntityType;
  taxForm1099SType?: number;
  gabBusinessOrganization?: IGABBusinessOrganization;
}

export interface IOrganizationAddress {
  id?: number;
  city?: string;
  state?: string;
  zip?: string;
  county?: string;
  country?: string;
  streetLine1?: string;
  streetLine2?: string;
  streetLine3?: string;
  streetLine4?: string;
  addressType?: number;
}
