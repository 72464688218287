import { ISiteRowProps } from 'views/MultisiteSitesList/types';
import {
  BulkUpdatesModal,
  IMultiSiteBulkUpdatesContext,
} from '../Context/types';

export const LOANS = [
  {
    id: 227969,
    fileId: 50491838,
    fastId: 1028957424,
    amount: 10,
    sequenceNumber: 4,
    liabilityAmount: 10,
    projectLoanFastId: 1028957424,
    lenderFastId: 850699909,
    uniqueKey: null,
  },
  {
    id: 128512,
    fileId: 50491838,
    fastId: 1028120009,
    amount: 10,
    sequenceNumber: 1,
    liabilityAmount: 10,
    projectLoanFastId: null,
    lenderFastId: 849010783,
    uniqueKey: null,
  },
  {
    id: 142652,
    fileId: 50491838,
    fastId: 1029158865,
    amount: 10,
    sequenceNumber: 3,
    liabilityAmount: 10,
    projectLoanFastId: null,
    lenderFastId: 849852095,
    uniqueKey: null,
  },
  {
    id: 138234,
    fileId: 50491838,
    fastId: 1029104336,
    amount: 0,
    sequenceNumber: 2,
    liabilityAmount: 8,
    projectLoanFastId: null,
    lenderFastId: 849806973,
    uniqueKey: null,
  },
];

export const SITE_WITH_LOAN_AMOUNTS = [
  {
    fileID: 50528361,
    loanAmounts: [
      {
        id: 227969,
        fileId: 50528361,
        fastId: 1030187425,
        amount: 10,
        sequenceNumber: 4,
        liabilityAmount: 10,
        projectLoanFastId: 1028503306,
        lenderFastId: 850699909,
        uniqueKey: null,
      },
      {
        id: 128512,
        fileId: 50528361,
        fastId: 1028120009,
        amount: 10,
        sequenceNumber: 1,
        liabilityAmount: 10,
        projectLoanFastId: null,
        lenderFastId: 849010783,
        uniqueKey: null,
      },
      {
        id: 142652,
        fileId: 50528361,
        fastId: 1029158865,
        amount: 10,
        sequenceNumber: 3,
        liabilityAmount: 10,
        projectLoanFastId: null,
        lenderFastId: 849852095,
        uniqueKey: null,
      },
      {
        id: 138234,
        fileId: 50528361,
        fastId: 1029104336,
        amount: 0,
        sequenceNumber: 2,
        liabilityAmount: 8,
        projectLoanFastId: null,
        lenderFastId: 849806973,
        uniqueKey: null,
      },
    ],
    payoffLoans: [],
  },
  {
    loanAmounts: [
      {
        id: 126773,
        fileId: 50501644,
        fastId: 1028957520,
        amount: 154000,
        sequenceNumber: 2,
        liabilityAmount: 154000,
        projectLoanFastId: 1028503294,
        lenderFastId: 849687207,
        uniqueKey: null,
      },
      {
        id: 136518,
        fileId: 50501644,
        fastId: 1029084218,
        amount: 13000,
        sequenceNumber: 5,
        liabilityAmount: 13000,
        projectLoanFastId: null,
        lenderFastId: 849790348,
        uniqueKey: null,
      },
      {
        id: 136618,
        fileId: 50501644,
        fastId: 1029085203,
        amount: 1320,
        sequenceNumber: 8,
        liabilityAmount: 1320,
        projectLoanFastId: null,
        lenderFastId: 849791132,
        uniqueKey: null,
      },
      {
        id: 136519,
        fileId: 50501644,
        fastId: 1029084219,
        amount: 132000,
        sequenceNumber: 6,
        liabilityAmount: 132000,
        projectLoanFastId: null,
        lenderFastId: 849790351,
        uniqueKey: null,
      },
      {
        id: 128513,
        fileId: 50501644,
        fastId: 1028244387,
        amount: 10,
        sequenceNumber: 1,
        liabilityAmount: 10,
        projectLoanFastId: 1028598410,
        lenderFastId: 849110402,
        uniqueKey: null,
      },
      {
        id: 136516,
        fileId: 50501644,
        fastId: 1029084216,
        amount: 134000,
        sequenceNumber: 3,
        liabilityAmount: 134000,
        projectLoanFastId: null,
        lenderFastId: 849790341,
        uniqueKey: null,
      },
      {
        id: 142653,
        fileId: 50501644,
        fastId: 1029158866,
        amount: 10,
        sequenceNumber: 9,
        liabilityAmount: 10,
        projectLoanFastId: null,
        lenderFastId: 849852098,
        uniqueKey: null,
      },
      {
        id: 136517,
        fileId: 50501644,
        fastId: 1029084217,
        amount: 124000,
        sequenceNumber: 4,
        liabilityAmount: 124000,
        projectLoanFastId: null,
        lenderFastId: 849790345,
        uniqueKey: null,
      },
      {
        id: 136542,
        fileId: 50501644,
        fastId: 1029084337,
        amount: 13200,
        sequenceNumber: 7,
        liabilityAmount: 13200,
        projectLoanFastId: null,
        lenderFastId: 849790488,
        uniqueKey: null,
      },
    ],
    fileID: 50501644,
  },
  {
    loanAmounts: [
      {
        id: 122800,
        fileId: 50528359,
        fastId: 1028908804,
        amount: 15000,
        sequenceNumber: 2,
        liabilityAmount: 15000,
        projectLoanFastId: null,
        lenderFastId: 849647172,
        uniqueKey: null,
      },
      {
        id: 122801,
        fileId: 50528359,
        fastId: 1028908807,
        amount: 0,
        sequenceNumber: 3,
        liabilityAmount: 0,
        projectLoanFastId: null,
        lenderFastId: null,
        uniqueKey: null,
      },
      {
        id: 98031,
        fileId: 50528359,
        fastId: 1028601395,
        amount: 0,
        sequenceNumber: 1,
        liabilityAmount: 0,
        projectLoanFastId: null,
        lenderFastId: null,
        uniqueKey: null,
      },
      {
        id: 122802,
        fileId: 50528359,
        fastId: 1028908809,
        amount: 150,
        sequenceNumber: 4,
        liabilityAmount: 150,
        projectLoanFastId: 1028957424,
        lenderFastId: 849647187,
        uniqueKey: null,
      },
    ],
    payoffLoans: [
      {
        id: 1029131384,
        fileId: 50528359,
        fastId: 1029131384,
        amount: 0,
        sequenceNumber: 3,
        liabilityAmount: null,
        projectLoanFastId: 1028988197,
        lenderFastId: 849829308,
      },
    ],
    fileID: 50528359,
  },
];

const siteOrdersResponse = {
  files: [
    {
      ownerLiabilities: [
        {
          id: 10021,
          fileId: 50501644,
          amount: null,
          sequenceNumber: 1,
        },
        {
          id: 10022,
          fileId: 50501644,
          amount: null,
          sequenceNumber: 2,
        },
        {
          id: 10023,
          fileId: 50501644,
          amount: null,
          sequenceNumber: 3,
        },
        {
          id: 10024,
          fileId: 50501644,
          amount: null,
          sequenceNumber: 4,
        },
        {
          id: 10025,
          fileId: 50501644,
          amount: null,
          sequenceNumber: 5,
        },
      ],
      salesPrices: [
        {
          fileId: 50501644,
          amount: null,
          sequenceNumber: 1,
        },
        {
          fileId: 50501644,
          amount: null,
          sequenceNumber: 2,
        },
        {
          fileId: 50501644,
          amount: null,
          sequenceNumber: 3,
        },
        {
          fileId: 50501644,
          amount: null,
          sequenceNumber: 4,
        },
        {
          fileId: 50501644,
          amount: null,
          sequenceNumber: 5,
        },
      ],
      addresses: [
        {
          addressId: 10005,
          fileId: 50501644,
          street: '2',
          city: 'FL',
          county: null,
          state: 'FL',
          zip: '12345',
          country: 'USA',
          apns: [],
          seqNum: 1,
          fastId: 356284425,
          propertyType: null,
          addressType: null,
        },
      ],
      buyers: [],
      sellers: [],
      offices: [],
      productionOffices: [],
      documents: null,
      businessSource: null,
      directedBy: null,
      businessParties: [],
      thumbnailUrl: null,
      services: [],
      loanAmounts: [
        {
          id: 10005,
          fileId: 50501644,
          amount: 0.0,
          sequenceNumber: 1,
          liabilityAmount: 0.0,
          uniqueKey: null,
        },
      ],
      businessPartyAndEmployees: [],
      businessSourceContact: null,
      isCustomerDocumentExist: false,
      products: [
        {
          id: 1,
          fastId: 886,
          name: 'ALTA Expanded (Eagle Loan) Policy',
        },
        {
          id: 10006,
          fastId: 866,
          name: 'ALTA Construction Loan 1992 Policy',
        },
      ],
      fileID: 50501644,
      name: null,
      fileNumber: '1135509F2',
      status: 'Open',
      totalSalesPrice: 0.0,
      openDate: '2022-08-31T08:38:25',
      closeDate: null,
      settlementDate: null,
      estimatedSettlementDate: null,
      transactionType: 'Bulk Sale',
      fileType: 'Site',
      projectFileId: 50491401,
      projectFileNumber: null,
      isCloseDateEstimated: false,
      _meta: {
        permissions: [
          'Fast',
          'WireAccounts',
          'FeeEntry',
          'SalesTaxOverride',
          'IBAEntry',
          'EscrowFileEntry',
          'EditSSNTIN',
        ],
        projectFileData: null,
      },
    },
    {
      ownerLiabilities: [
        {
          id: 10016,
          fileId: 50491838,
          amount: null,
          sequenceNumber: 1,
        },
        {
          id: 10017,
          fileId: 50491838,
          amount: null,
          sequenceNumber: 2,
        },
        {
          id: 10018,
          fileId: 50491838,
          amount: null,
          sequenceNumber: 3,
        },
        {
          id: 10019,
          fileId: 50491838,
          amount: null,
          sequenceNumber: 4,
        },
        {
          id: 10020,
          fileId: 50491838,
          amount: null,
          sequenceNumber: 5,
        },
      ],
      salesPrices: [
        {
          fileId: 50501644,
          amount: null,
          sequenceNumber: 1,
        },
        {
          fileId: 50501644,
          amount: null,
          sequenceNumber: 2,
        },
        {
          fileId: 50501644,
          amount: null,
          sequenceNumber: 3,
        },
        {
          fileId: 50501644,
          amount: null,
          sequenceNumber: 4,
        },
        {
          fileId: 50501644,
          amount: null,
          sequenceNumber: 5,
        },
      ],
      addresses: [
        {
          addressId: 10004,
          fileId: 50491838,
          street: '1',
          city: 'NY',
          county: null,
          state: 'NY',
          zip: '12345',
          country: 'USA',
          apns: [],
          seqNum: 1,
          fastId: 356259233,
          propertyType: null,
          addressType: null,
        },
      ],
      buyers: [],
      sellers: [],
      offices: [],
      productionOffices: [],
      documents: null,
      businessSource: null,
      directedBy: null,
      businessParties: [],
      thumbnailUrl: null,
      services: [],
      loanAmounts: [
        {
          id: 10004,
          fileId: 50491838,
          amount: 0.0,
          sequenceNumber: 1,
          liabilityAmount: 0.0,
          uniqueKey: null,
        },
      ],
      businessPartyAndEmployees: [],
      businessSourceContact: null,
      isCustomerDocumentExist: false,
      products: [
        {
          id: 10004,
          fastId: 4034,
          name: 'Agency FastSearch',
        },
        {
          id: 10005,
          fastId: 884,
          name: 'Abstract',
        },
      ],
      fileID: 50491838,
      name: null,
      fileNumber: '1135509F1',
      status: 'Open',
      totalSalesPrice: 0.0,
      openDate: '2022-08-19T09:03:58',
      closeDate: null,
      settlementDate: null,
      estimatedSettlementDate: null,
      transactionType: 'Bulk Sale',
      fileType: 'Site',
      projectFileId: 50491401,
      projectFileNumber: null,
      isCloseDateEstimated: false,
      _meta: {
        permissions: [
          'Fast',
          'WireAccounts',
          'FeeEntry',
          'SalesTaxOverride',
          'IBAEntry',
          'EscrowFileEntry',
          'EditSSNTIN',
        ],
        projectFileData: null,
      },
    },
  ],
  _meta: {
    pagination: {
      limit: 10,
      page: 1,
      total: 2,
      pages: 1,
    },
    columnFilterAggregations: {
      locations: {
        usa: {
          fl: 2,
        },
      },
    },
  },
};

const transactionTypeResponse = [
  { description: 'Accommodation', id: 837 },
  { description: 'Bulk Sale', id: 5 },
  { description: 'Construction Disbursement', id: 1245 },
  { description: 'Construction Finance', id: 2997 },
  { description: 'Construction Loan', id: 8 },
  { description: 'Equity Loan', id: 4 },
  { description: 'Foreclosure', id: 251 },
  { description: 'Limited Escrow', id: 9 },
  { description: 'Mortgage Modification', id: 1655 },
  { description: 'Mtg Mod w/Endorsement', id: 2995 },
  { description: 'Mtg Mod w/Increased Liability', id: 2996 },
  { description: 'Refinance', id: 3 },
  { description: 'REO', id: 836 },
  { description: 'REO Sale w/Mortgage', id: 1656 },
  { description: 'REO Sale/Cash', id: 1657 },
  { description: 'Sale w/Construction Loan', id: 2994 },
  { description: 'Sale w/Mortgage', id: 1 },
  { description: 'Sale/Cash', id: 2 },
  { description: 'Sale/Exchange', id: 10 },
  { description: 'Search Package', id: 7 },
  { description: 'Second Mortgage', id: 1246 },
  { description: 'Settlement Statement Only', id: 1244 },
  { description: 'Short Sale', id: 1646 },
  { description: 'Short Sale w/Mortgage', id: 1842 },
  { description: 'Short Sale/Cash', id: 1843 },
];

const siteOrderRowItems: ISiteRowProps[] = [
  {
    address: '123 Street test',
    businessPartyAndEmployees: [],
    closingDate: new Date().toDateString(),
    fileId: 12345,
    location: 'Some city',
    name: 'Mocked file',
    number: '12334551MF',
    status: 'Open',
    _meta: null,
    transactionType: 'Accommodation',
    state: 'IO',
    city: 'Random city',
    county: 'My County',
    closingGroup: {
      id: 0,
      name: 'Mocked group 1',
      orders: [],
      projectFileId: 1,
      isEstimatedClosingDate: false,
    },
  },
  {
    address: '312 West Avenue',
    businessPartyAndEmployees: [],
    closingDate: new Date().toDateString(),
    fileId: 54312,
    location: 'County fake',
    name: '',
    number: '21312312CF',
    status: 'Closed',
    _meta: null,
    transactionType: 'Bulk Sale',
    state: 'AK',
    city: 'Gold City',
    county: 'Countyless',
    closingGroup: {
      id: 4,
      name: 'Mocked group 55',
      orders: [],
      projectFileId: 13,
      isEstimatedClosingDate: false,
    },
  },
  {
    address: '1 Strong road',
    businessPartyAndEmployees: [],
    closingDate: new Date().toDateString(),
    fileId: 9999999999,
    location: 'New York, NY | Brooklyn',
    name: 'A file with a very interesting and long name',
    number: '12300012300VLN',
    status: 'Canceled',
    _meta: null,
    transactionType: 'Equity Loan',
    state: 'NY',
    city: 'New York',
    county: 'Brooklyn',
    closingGroup: {
      id: 3,
      name: 'Group cool',
      orders: [],
      projectFileId: 2,
      isEstimatedClosingDate: false,
    },
  },
];

const salesPriceResponse = [
  {
    fileId: 12345,
    amount: 5000,
    sequenceNumber: 1,
  },
  {
    fileId: 12345,
    amount: 2500,
    sequenceNumber: 2,
  },
  {
    fileId: 12345,
    amount: 1559,
    sequenceNumber: 3,
  },
  {
    fileId: 12345,
    amount: 2341,
    sequenceNumber: 4,
  },
  {
    fileId: 12345,
    amount: 3231.4,
    sequenceNumber: 5,
  },
];

const ownerLiabilitiesResponse = [
  {
    id: 1,
    fileId: 12345,
    amount: 5000,
    sequenceNumber: 1,
  },
  {
    id: 2,
    fileId: 12345,
    amount: 2323,
    sequenceNumber: 2,
  },
  {
    id: 3,
    fileId: 12345,
    amount: 5021.24,
    sequenceNumber: 3,
  },
  {
    id: 4,
    fileId: 12345,
    amount: 32300,
    sequenceNumber: 4,
  },
  {
    id: 5,
    fileId: 12345,
    amount: 15120.2,
    sequenceNumber: 5,
  },
];

const loansResponse = {
  loans: [
    {
      id: 1028113811,
      organizationId: 0,
      gabCode: null,
      organization: null,
      contact: null,
      loanKind: 1,
      priority: 1,
      amount: 0.0,
      loanLiabilityAmount: 0.0,
      lenderId: 0,
    },
    {
      id: 1028503306,
      organizationId: 81835797,
      gabCode: '3740288',
      organization: 'ABC',
      contact: null,
      loanKind: 1,
      priority: 2,
      amount: 2000.0,
      loanLiabilityAmount: 2000.0,
      lenderId: 849313582,
    },
    {
      id: 1028907970,
      organizationId: 15990030,
      gabCode: '914789',
      organization: 'CoBank, ACB',
      contact: null,
      loanKind: 1,
      priority: 3,
      amount: 210000.0,
      loanLiabilityAmount: 210000.0,
      lenderId: 849646474,
    },
    {
      id: 1028912795,
      organizationId: 15990030,
      gabCode: '914789',
      organization: 'CoBank, ACB',
      contact: null,
      loanKind: 1,
      priority: 4,
      amount: 543000.0,
      loanLiabilityAmount: 543210.0,
      lenderId: 849650464,
    },
    {
      id: 1028957421,
      organizationId: 64007823,
      gabCode: '2404603',
      organization: 'ABCD Trust',
      contact: null,
      loanKind: 1,
      priority: 5,
      amount: 123450.0,
      loanLiabilityAmount: 123450.0,
      lenderId: 849687115,
    },
    {
      id: 1028957424,
      organizationId: 64007823,
      gabCode: '2404603',
      organization: 'ABCD Trust',
      contact: null,
      loanKind: 1,
      priority: 7,
      amount: 212000.0,
      loanLiabilityAmount: 212000.0,
      lenderId: 849687125,
    },
    {
      id: 1028988197,
      organizationId: 57769087,
      gabCode: '1888259',
      organization: 'ABCWUA',
      contact: '',
      loanKind: 0,
      priority: 1,
      amount: 150.0,
      loanLiabilityAmount: null,
      lenderId: 849711068,
    },
    {
      id: 1028988205,
      organizationId: 81835797,
      gabCode: '3740288',
      organization: 'ABC',
      contact: '',
      loanKind: 0,
      priority: 2,
      amount: 70.0,
      loanLiabilityAmount: null,
      lenderId: 849711082,
    },
  ],
};

export const generateContext = (
  currentModal: BulkUpdatesModal,
  modalProps?: any
): IMultiSiteBulkUpdatesContext => {
  return {
    actions: {
      openModal: jest.fn(),
      closeModal: jest.fn(),
      setModalProps: jest.fn(),
    },
    state: {
      currentModal: currentModal,
      modalProps: modalProps,
    },
  };
};

export const REQUESTS = {
  siteOrdersResponse,
  transactionTypeResponse,
  salesPriceResponse,
  ownerLiabilitiesResponse,
  loansResponse,
};

export const OBJECTS = {
  siteOrderRowItems,
};
