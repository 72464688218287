import React from 'react';
import { ErrorPageWithGraphic, ErrorCode } from 'components/ErrorPage';
import { trout } from 'config/theme';
import styled from 'styled-components';
import { SuperSymbols } from './Resources/Common';

// Since 404 pages could have any url we cannot match by location in order to hide
// the navigation (as we do in the Header component).
// What we do in this case is set a `forceHideNav` flag in the app state whenever the
// view opens or closes, which will in turn hide or show the navigation.
export const SignupExpired = () => {
  const titleExpired = (
    <>
      Oops. Better click faster next time. <br />
      Invite expired...
    </>
  );

  return (
    <ErrorPageWithGraphic
      statusCode={ErrorCode.SECURITY_EXPIRED}
      title={titleExpired}
      titleSize="24px"
      showCode={false}
      titleStyles={{
        lineHeight: '32px',
        marginTop: 18,
        marginBottom: 12,
      }}
      statusCodeStyles={{
        fontSize: 16,
        fontWeight: 350,
        lineHeight: '26px',
      }}
      description={
        <DescriptionContainer>
          To better protect your orders, our invite links expires after{' '}
          {AppConfig.mfaExpirationDays} days.
          <br />
          To get reinvited, please call ClarityFirst
          <SuperSymbols>&reg;</SuperSymbols> Support at 833.627.2255 or talk to
          your representative.
        </DescriptionContainer>
      }
    />
  );
};

const DescriptionContainer = styled.div`
  line-height: 26px;
  color: ${trout};

  a {
    margin-left: 3px;
  }
`;
