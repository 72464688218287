import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import StyledReactTooltip, {
  IStyledReactTooltipProps,
} from 'components/ToolTip/StyledReactTooltip';
import { Row, IBoxProps } from '../Box';
import { mystic, linkWater } from 'config/theme';
import { PopoverHeader } from './PopoverHeader';

export interface IPopoverOption {
  /**
   * Optional: If false, this popover option will not close the popover by default when it is clicked
   * Default: true
   */
  autoClosePopover?: boolean;

  /**
   * content to be displayed in the main section of the popover item row
   */
  content: React.ReactNode;

  /**
   * displays the content without any wrapper
   * Usable when the Consumer wants to handle its own actions
   */
  isRawContent?: boolean;

  /**
   * Optional: This number gives the component a maximum number of levels up in
   * the DOM to search when a mouseOver event occurs, in order to determine
   * whether or not the element being moused *into* is a sub-element of the
   * option. Doing this avoids flicker. By default this is set to 5, but other
   * values can be provided via this prop in case the option `content` is
   * unusually complex or nested.
   */
  contentDepth?: number;
  /**
   * Optional: If present, this string will be added to the underlying DOM element attributes.
   * This is useful for E2E tests and Pendo tracking
   */
  dataFor?: string;
  /**
   * Optional: If true, this popover item will be "greyed out" and not clickable.
   */
  disabled?: boolean;
  /**
   * Optional: If true, the tooltip for the popover item will be shown regardless whether the item is disabled or not.
   */
  showToolTip?: boolean;
  /**
   * Optional: If present, this text will be displayed in a tooltip when
   * disabled popover items are hovered over.
   */
  disabledTooltipText?: React.ReactNode;
  /**
   * Optional: Whether the current option is a header, which will add some
   * elements like a navigation bar, and some padding adjustment
   */
  header?: boolean;
  /**
   * Optional?: If true, this popover item will not be displayed
   */
  hidden?: boolean;
  /**
   * Optional: icon to be displayed to the left of the content
   */
  icon?: JSX.Element;
  /**
   * Optional: size of the icon in square px
   */
  iconSize?: number;
  /**
   * Optional: handler to be called when the popover item is clicked
   */
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  /**
   * Optional: to be used as css class
   */
  pendoClassName?: string | undefined;
  /**
   * tooltipProps
   */
  tooltipProps?: IStyledReactTooltipProps;
  /**
   * Optional: for pendo tracking
   */
  pendoDataId?: string | undefined;
  /**
   * Optional: for static DOM id
   */
  domIdPrefix?: string | undefined;
  /**
   * Optional: add full header with close popover icon
   */
  fullHeaderTitle?: string;
  /**
   * Optional: pass in overriding styles
   */
  style?: React.CSSProperties;
  optionStyle?: React.CSSProperties;
  /**
   * Optional: define the option's data-testid, if not provided defaults the option data-testid to "popover-option-item"
   */
  dataTestId?: string;
  /**
   * Optional: sets role for option element
   */
  role?: string;
}

interface IOptionProps {
  index: number;
  option: IPopoverOption;
  setOpen: (open: boolean) => void;
}

export default function Option({ index, option, setOpen }: IOptionProps) {
  const {
    autoClosePopover,
    content,
    isRawContent,
    contentDepth,
    dataFor,
    disabled,
    disabledTooltipText,
    showToolTip,
    header,
    icon,
    iconSize,
    onClick,
    pendoClassName,
    tooltipProps,
    pendoDataId,
    domIdPrefix,
    fullHeaderTitle,
    style,
    optionStyle,
    dataTestId = 'popover-option-item',
    role,
  } = option;
  // Not really necessary to have this state. This is mainly to fix an issue in
  // IE11 where the tooltip is not disappearing on mouseout.
  const [tooltipIsVisible, setTooltipVisibility] = useState(false);

  const optionRef = useRef(null as any);

  const domIdMainPart = domIdPrefix ?? index;

  useEffect(() => {
    optionRef.current = document.getElementById(
      `popup-popover-option-${domIdMainPart}`
    );
  }, []);

  if (isRawContent) {
    return <>{content}</>;
  }

  return (
    <>
      {fullHeaderTitle && (
        <PopoverHeader
          title={fullHeaderTitle}
          onClose={(ev) => {
            ev.stopPropagation();
            ev.preventDefault();
            setOpen(false);
          }}
        />
      )}
      <StyledOption
        alignItems="center"
        className={`popup-popover-option ${pendoClassName ?? ''}`}
        data-for={
          (disabled || showToolTip) && disabledTooltipText
            ? `popover-tooltip-${index}`
            : dataFor
        }
        data-testid={dataTestId}
        data-tip={!!((disabled || showToolTip) && disabledTooltipText)}
        data-idx={pendoDataId || domIdPrefix}
        disabled={!!disabled}
        header={!!header}
        id={`popup-popover-option_${domIdMainPart}`}
        isClickable={!!onClick}
        style={style}
        icon={icon}
        onClick={
          disabled || !onClick
            ? (e) => {
                // Below is to prevent closing the popup popover when a
                // disabled popover item is clicked on.
                // TODO: test this in IE
                e.stopPropagation();
              }
            : (e: React.MouseEvent<HTMLElement>) => {
                e.preventDefault();
                e.stopPropagation();

                // undefined or null should default to true
                if (autoClosePopover ?? true) {
                  setOpen(false);
                }

                onClick(e);
              }
        }
        onMouseOver={() => {
          if (!tooltipIsVisible) setTooltipVisibility(true);
        }}
        onMouseOut={(e) => {
          let mousedIntoNode = e.relatedTarget as Node | null;
          let isDescendant = false;
          const levelsToSearch = contentDepth || 5;
          // search up to 5 levels up to see if this node is still "inside of" the
          // option. If so, don't change the state. This avoids flicker.
          for (let i = 0; i < levelsToSearch; i++) {
            if (mousedIntoNode === optionRef.current) {
              isDescendant = true;
              break;
            }
            mousedIntoNode = mousedIntoNode?.parentNode || null;
          }
          if (isDescendant) return;
          setTooltipVisibility(false);
        }}
        role={role}
      >
        {icon && (
          <IconContainer
            alignItems="center"
            disabled={!!disabled}
            size={iconSize}
            data-testid="icon-container"
          >
            {icon}
          </IconContainer>
        )}
        <OptionContent disabled={!!disabled} style={optionStyle}>
          {content}
        </OptionContent>
        {tooltipIsVisible && (
          <StyledReactTooltip
            id={`popover-tooltip-${index}`}
            content={disabledTooltipText}
            {...tooltipProps}
          />
        )}
      </StyledOption>
    </>
  );
}

interface IStyledOptionProps extends IBoxProps {
  /**
   * Flag to indicate whether this option is disabled
   */
  disabled: boolean;
  /**
   * Whether our option is clickable, or consists of content
   */
  isClickable: boolean;
  /**
   * Optional: Whether the current option is a header, which will add some
   * elements like a navigation bar, and some padding adjustment
   */
  header: boolean;

  /**
   * If no icon is passed as props, some styles are applied to guarantee
   * the paddings are being correctly applied when the <IconContainer/> is not rendered
   */
  icon?: JSX.Element;
}

export const ROW_DEFAULT_PADDING = 2;

export const StyledOption = styled(Row)<IStyledOptionProps>`
  cursor: ${({ disabled, isClickable }) =>
    !disabled && isClickable ? 'pointer' : 'default'};
  min-height: 48px;
  padding: ${ROW_DEFAULT_PADDING}px 30px ${ROW_DEFAULT_PADDING}px
    ${ROW_DEFAULT_PADDING}px;
  white-space: nowrap;
  ${({ header }) =>
    header
      ? `
  border-bottom: 1px ${linkWater} solid
  padding-bottom: 12px;
  padding-top: 12px;
  margin-top: 0px;
  margin-bottom: 8px;
  `
      : ''}

  &:last-child {
    margin-bottom: 8px;
  }

  &:hover {
    background-color: ${({ disabled, isClickable }) =>
      !disabled && isClickable ? mystic : 'inherit'};
  }

  ${({ icon }) =>
    !icon &&
    `
    padding-inline: 16px;
  `}
`;
Option.displayName = 'StyledOption';

export const OptionContent = styled.div<{ disabled: boolean }>`
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

export const computeHorizontalIconPadding = (iconSize?: number) =>
  (iconSize ?? 30) / 1.5;

export const IconContainer = styled(Row)<{ disabled: boolean; size?: number }>`
  padding: 10px ${(props) => computeHorizontalIconPadding(props.size)}px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  svg {
    align-self: center;
    height: ${(props) => props.size || 30}px;
    width: ${(props) => props.size || 30}px;
  }
`;
IconContainer.displayName = 'IconContainer';
