import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import styled from 'styled-components';
import { gray10, primaryBlue4 } from 'config/colors';
import CloseIcon from 'icons/Close';
import { SMALL_BODY_MEDIUM } from 'config/typography';

export interface IPopoverHeaderProps {
  title: string | React.ReactNode;
  onClose: (e: React.MouseEvent<HTMLElement>) => void;
  style?: React.CSSProperties;
}

export const PopoverHeader = ({
  title,
  onClose,
  style,
}: IPopoverHeaderProps) => {
  return (
    <HeaderWrapper style={{ ...style }} onClick={(e) => e.stopPropagation()}>
      <Header>{title}</Header>
      {onClose && (
        <IconButton
          data-testid="popover-close"
          tabIndex="0"
          className="popover-close-icon"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      )}
    </HeaderWrapper>
  );
};

const Header = styled.div`
  ${SMALL_BODY_MEDIUM};
  color: ${gray10};
`;

const HeaderWrapper = styled.div`
  display: flex;
  cursor: default;
  margin-top: -8px;
  padding: 4px 4px 4px 16px;
  border-bottom: 1px solid ${primaryBlue4};
  align-items: center;
  .MuiButtonBase-root {
    margin-left: auto;
  }
`;
